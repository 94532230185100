import React from "react";
import moment from "moment-timezone";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;
const MONTH = 30 * DAY;
const YEAR = 365 * DAY;


function FriendlyTime({ date, asSuffix }) {
	const [ timestamp_now, setTimestampNow ] = React.useState(new Date().getTime());

	React.useEffect(() => {
		const handle = setInterval(() => {
			setTimestampNow(new Date().getTime());
		}, 5000);
		return () => {
			clearInterval(handle);
		};
	}, []);

	const timestamp_value = new Date(date).getTime();
	const timestamp_diff_sec = Math.round((timestamp_now - timestamp_value) / 1000);

	const full = moment(date).format("D MMM Y, H:mm:ss");

	let str;
	if (timestamp_diff_sec > 1.5 * YEAR)          str = `hace ${Math.round(timestamp_diff_sec / YEAR)} años`;
	else if (timestamp_diff_sec > 11.5 * MONTH)   str = `hace 1 año`;
	else if (timestamp_diff_sec > 1.5 * MONTH)    str = `hace ${Math.round(timestamp_diff_sec / MONTH)} meses`;
	else if (timestamp_diff_sec > 3.5 * WEEK)     str = `hace 1 mes`;
	else if (timestamp_diff_sec > 1.5 * WEEK)     str = `hace ${Math.round(timestamp_diff_sec / WEEK)} semanas`;
	else if (timestamp_diff_sec > 6.5 * DAY)      str = `hace 1 semana`;
	else if (timestamp_diff_sec > 1.5 * DAY)      str = `hace ${Math.round(timestamp_diff_sec / DAY)} días`;
	else if (timestamp_diff_sec > 23.5 * HOUR)    str = `hace 1 día`;
	else if (timestamp_diff_sec > 1.5 * HOUR)     str = `hace ${Math.round(timestamp_diff_sec / HOUR)} horas`;
	else if (timestamp_diff_sec > 59.5 * MINUTE)  str = `hace 1 hora`;
	else if (timestamp_diff_sec > 1.5 * MINUTE)   str = `hace ${Math.round(timestamp_diff_sec / MINUTE)} minutos`;
	else if (timestamp_diff_sec > 50)             str = `hace 1 minuto`;
	else if (timestamp_diff_sec >= 0)             str = `hace unos segundos`;
	else if (timestamp_diff_sec < -1.5 * YEAR)    str = `en ${Math.round(-timestamp_diff_sec / YEAR)} años`;
	else if (timestamp_diff_sec < -11.5 * MONTH)  str = `en 1 año`;
	else if (timestamp_diff_sec < -1.5 * MONTH)   str = `en ${Math.round(-timestamp_diff_sec / MONTH)} meses`;
	else if (timestamp_diff_sec < -1.5 * DAY)     str = `en ${Math.round(-timestamp_diff_sec / DAY)} días`;
	else if (timestamp_diff_sec < -1.5 * HOUR)    str = `en ${Math.round(-timestamp_diff_sec / HOUR)} horas`;
	else if (timestamp_diff_sec < -1.5 * MINUTE)  str = `en ${Math.round(-timestamp_diff_sec / MINUTE)} minutos`;
	else if (timestamp_diff_sec < -50)            str = `en 1 minuto`;
	else                                          str = `en unos segundos`;

	const tooltip = <Tooltip>{full}</Tooltip>;
	const base = <OverlayTrigger placement="top" overlay={tooltip}><span>{str}</span></OverlayTrigger>;
	return asSuffix ? <small className="text-muted text-nowrap ml-1">&mdash; {base}</small> : base;
}

export default FriendlyTime;
