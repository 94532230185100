import React from "react";
import Card from "react-bootstrap/Card";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { FaSearch, FaProjectDiagram, FaShip } from "react-icons/fa";
import MisBusquedasDespachante from "./MisBusquedasDespachante";
import MisBusquedasAgente from "./MisBusquedasAgente";


function DashboardBusquedasActivas() {
	const [ what, setWhat ] = React.useState("agente");

	return (
		<Card>
			<Card.Header as="h5">
				<span className="mr-3"><FaSearch />{' '}Mis Búsquedas</span>
				<ToggleButtonGroup type="radio" name="options" defaultValue={what} onChange={value => setWhat(value)}>
					<ToggleButton variant="link" value="agente"><FaShip />Agente&nbsp;<span className="d-none d-md-inline">de carga</span></ToggleButton>
					<ToggleButton variant="link" value="despachante"><FaProjectDiagram />Despachante&nbsp;<span className="d-none d-md-inline">de aduana</span></ToggleButton>
				</ToggleButtonGroup>
			</Card.Header>
			<Card.Body>
				{ what === "despachante" ? <MisBusquedasDespachante /> : <MisBusquedasAgente />}
			</Card.Body>
		</Card>
	);
}

export default DashboardBusquedasActivas;
