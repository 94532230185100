import React from "react";
import { Link } from "@reach/router";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Page from "../../Components/Page.js";
import SetupImex from "../../Components/SetupImex.js";
import { useUserContext } from "../../UserContext";


function PageUsuariosSetupImex() {
	const userContext = useUserContext();

	const handleProfileDataSave = userContext.updateUser;

	return (
		<Page>
			<Page.Title showBackButton>
				<Breadcrumb>
					<Breadcrumb.Item linkAs="span"><Link to="/dashboard">Mi Cuenta</Link></Breadcrumb.Item>
					<Breadcrumb.Item active>Importador/Exportador</Breadcrumb.Item>
				</Breadcrumb>
			</Page.Title>
			<Page.Content>
				<SetupImex onSave={handleProfileDataSave} />
			</Page.Content>
		</Page>
	);
}

export default PageUsuariosSetupImex;
