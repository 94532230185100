import React from "react";
import { Link } from "@reach/router";
import CotizacionAgente from "./CotizacionAgente.js";
import BusquedaAgenteMiCotizacion from "../../Components/busquedas/BusquedaAgenteMiCotizacion.js";
import { useUserContext } from "../../UserContext";
import Card from "react-bootstrap/Card";

function BusquedaAgenteCotizaciones({ busqueda, onChange }) {
	onChange = onChange ? onChange : () => {};
	const userContext = useUserContext();
	const busquedaPropia = userContext.user._id.toString() == busqueda.user_id.toString();

	let content = null;
	if (busquedaPropia) {
		content = <CotizacionesOfrecidas busqueda={busqueda} />;
	} else if (userContext.user.is_agente) {
		content = <BusquedaAgenteMiCotizacion busqueda={busqueda} onSave={onChange} onCancelCotizacion={onChange} />;
	}
	return content;
}


function CotizacionesOfrecidas({ busqueda }) {
	const cotizaciones = busqueda.cotizaciones || [];

	let content;
	if (cotizaciones.length === 0) {
		let title, explanation;
		const busquedaIsClosed = busqueda && new Date(busqueda.end_date) < new Date();
		if (busquedaIsClosed) {
			title = "No se recibieron cotizaciones :(";
			explanation = <span>Esta búsqueda ha finalizado. Puedes <Link to="/busquedas/agente-de-carga/nueva">iniciar una nueva búsqueda</Link> para notificar a los agentes de carga y recibir cotizaciones.</span>;
		} else {
			title = "A la espera de cotizaciones...";
			explanation = "Te notificaremos a medida que los agentes de carga coticen esta operación.";
		}

		content = (
			<Card>
				<Card.Header>
					<Card.Title>{ title }</Card.Title>
				</Card.Header>
				<Card.Body>
					<Card.Text>{ explanation }</Card.Text>
				</Card.Body>
			</Card>
		);
	} else {
		content = (
			<>
				<h3 className="mb-4">{ cotizaciones.length === 1 ? "1 cotización disponible" : cotizaciones.length + " cotizaciones disponibles" }</h3>
				<p>Te notificaremos a medida que recibas nuevas cotizaciones.</p>
				{
					cotizaciones.map(c => (
						<div key={c._id} className="mb-4">
							<CotizacionAgente busqueda={busqueda} cotizacion={c} />
						</div>
					))
				}
			</>
		);
	}
	return content;
}

export default BusquedaAgenteCotizaciones;
