import React from "react";
import BusquedaDespachanteTeaser from "./BusquedaDespachanteTeaser";
import useReferenceTables from "../../data/referenceTables";
import { Loading } from "../ccQuery";


function ListadoBusquedasDespachante({ busquedas }) {
	const referenceTables = useReferenceTables();
	if (!Object.keys(referenceTables).length) {
		return <Loading />;
	}
	return (
		<>
			{ busquedas.length === 0
				? "En este momento no hay búsquedas de despachante abiertas que coincidan con tu perfil."
				: busquedas.map(b => (
				<p key={b._id}><BusquedaDespachanteTeaser busqueda={b} /></p>
			))}
		</>
	);
}

export default ListadoBusquedasDespachante;
