import React from "react";

import useReferenceTables from "../data/referenceTables";
import { FaComment } from "react-icons/fa";
import ClampLines from 'react-clamp-lines';

import "./BusquedaAgenteDetalles.scss";

function BusquedaAgenteDetalles({ busqueda }) {
	const referenceTables = useReferenceTables();
	if (Object.keys(referenceTables).length === 0) {
		return null;
	}

	return (
		<table className="BusquedaAgenteDetalles_Table table table-striped table-sm">
			<tbody>
				<tr>
					<td colSpan="2">
						<span className="Label">Mercaderia</span>
						<div className="Value">{ busqueda.mercaderia }</div>
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<span className="Label">Condición de Venta</span>
						<div className="Value">{ referenceTables.agente_incoterms.filter(t => busqueda.condicion_venta.indexOf(t.id) >= 0)[0].label }</div>
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<span className="Label">Lugar de Origen</span>
						<div className="Value">{ busqueda.lugar_origen }</div>
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<span className="Label">Lugar de Destino</span>
						<div className="Value">{ busqueda.lugar_destino }</div>
					</td>
				</tr>
				<tr>
					<td colSpan="2"><span className="d-flex justify-content-between flex-wrap"><span className="Label">Vía</span><span className="Value">{ referenceTables.agente_via.filter(t => busqueda.via.indexOf(t.id) >= 0)[0].label }</span></span></td>
				</tr>
				<tr>
					<td colSpan="2"><span className="d-flex justify-content-between flex-wrap"><span className="Label">Bultos</span><span className="Value">{ busqueda.cantidad_de_bultos } { busqueda.tipo_de_bulto }</span></span></td>
				</tr>
				<tr>
					<td colSpan="2"><span className="d-flex justify-content-between flex-wrap"><span className="Label">Peso Total</span><span className="Value">{ busqueda.peso_total }</span></span></td>
				</tr>
				<tr>
					<td colSpan="2"><span className="d-flex justify-content-between flex-wrap"><span className="Label">Volumen Total</span><span className="Value">{ busqueda.volumen_total }</span></span></td>
				</tr>
				<tr>
					<td colSpan="2">
						<span className="d-flex justify-content-between flex-wrap">
							<span className="Label">Dimensiones</span>
							<span className="Value text-right">
								<div className="text-nowrap">Largo: { busqueda.dimensiones_largo }</div>
								<div className="text-nowrap">Ancho: { busqueda.dimensiones_ancho }</div>
								<div className="text-nowrap">Alto: { busqueda.dimensiones_alto }</div>
							</span>
						</span>
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<span className="d-flex justify-content-between flex-wrap">
							<span className="Label">Contrata seguro?</span>
							<span className="Value">{ busqueda.contratar_seguro ? "SÍ" : "NO"}</span>
						</span>
					</td>
				</tr>
				<tr hidden={!busqueda.contratar_seguro}>
					<td colSpan="2">
						<span className="d-flex justify-content-between flex-wrap">
							<span className="Label">Valor y divisa</span>
							<span className="Value">{ parseFloat(busqueda.valor).toLocaleString("en") } { busqueda.divisa }</span>
						</span>
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<span className="d-flex justify-content-between flex-wrap">
							<span className="Label">Es Apilable?</span>
							<span className="Value">{ busqueda.es_apilable ? "SÍ" : "NO"}</span>
						</span>
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<span className="d-flex justify-content-between flex-wrap">
							<span className="Label">Es Rotable?</span>
							<span className="Value">{ busqueda.es_rotable ? "SÍ" : "NO"}</span>
						</span>
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<span className="d-flex justify-content-between flex-wrap">
							<span className="Label">Es IMO (mercadería peligrosa)?</span>
							<span className="Value">{ busqueda.es_imo ? "SÍ" : "NO"}</span>
						</span>
					</td>
				</tr>
				<tr hidden={!busqueda.es_imo}>
					<td colSpan="2" className="pl-5">
						<span className="d-flex justify-content-between flex-wrap">
							<span className="Label">U.N.</span><span className="Value">{ busqueda.imo_un }</span>
						</span>
					</td>
				</tr>
				<tr hidden={!busqueda.es_imo}>
					<td colSpan="2" className="pl-5">
						<span className="d-flex justify-content-between flex-wrap">
							<span className="Label">Clase</span><span className="Value">{ busqueda.imo_clase }</span>
						</span>
					</td>
				</tr>
				{
					busqueda.comentarios ?
						<tr>
							<td colSpan="2">
								<span className="Label"><FaComment className="mr-2" />Comentarios</span>
								<strong className="Value">
									<blockquote>
										<ClampLines
											text={busqueda.comentarios}
											lines={3}
											ellipsis="..."
											moreText="ver más"
											lessText="ver menos"
										/>
									</blockquote>
								</strong>
							</td>
						</tr>
						: null
					}
			</tbody>
		</table>
	);
}

export default BusquedaAgenteDetalles;
