import React from "react";
import PropTypes from "prop-types";
import BootstrapButton from "react-bootstrap/Button";
import { FaCog } from "react-icons/fa";

function Button(props) {
	const { loading, icon, children, disabled, className, ...otherProps } = props;
	const currentIcon = loading ? <FaCog className="icon-spin" /> : icon;
	const thisDisabled = !!loading || !!disabled;

	let thisClassName = className || "";
	if (thisDisabled) {
		thisClassName += " disabled";
	}

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<BootstrapButton disabled={thisDisabled} className={thisClassName} {...otherProps}>{currentIcon}{" "}{children}</BootstrapButton>
	);
}

Button.propTypes = {
	children: PropTypes.node,
	icon: PropTypes.element,
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
};

Button.defaultProps = {
	children: null,
	icon: null,
	loading: false,
	disabled: false,
};

export default Button;
