import React from "react";
import { Link } from "@reach/router";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FormBusquedaAgente from "../../Components/busquedas/FormBusquedaAgente";
import Page from "../../Components/Page";


function PageBuscarAgente() {
	return (
		<Page id="PageBuscarAgente">
			<Page.Title showBackButton>
				<Breadcrumb>
					<Breadcrumb.Item linkAs="span"><Link to="/dashboard">Mi Cuenta</Link></Breadcrumb.Item>
					<Breadcrumb.Item active>Pedido de cotización para Agentes de Carga</Breadcrumb.Item>
				</Breadcrumb>
			</Page.Title>
			<Page.Content>
				<FormBusquedaAgente />
			</Page.Content>
		</Page>
	);
}

export default PageBuscarAgente;
