import React from "react";
import { Link } from "@reach/router";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Page from "../../Components/Page.js";
import SetupDespachanteDeAduana from "../../Components/SetupDespachanteDeAduana.js";
import { useUserContext } from "../../UserContext";


function PageUsuariosSetupDespachanteDeAduana() {
	const userContext = useUserContext();

	const handleProfileDataSave = userContext.updateUser;

	return (
		<Page>
			<Page.Title showBackButton>
				<Breadcrumb>
					<Breadcrumb.Item linkAs="span"><Link to="/dashboard">Mi Cuenta</Link></Breadcrumb.Item>
					<Breadcrumb.Item active>Despachante de Aduana</Breadcrumb.Item>
				</Breadcrumb>
			</Page.Title>
			<Page.Content>
				<SetupDespachanteDeAduana onSave={handleProfileDataSave} />
			</Page.Content>
		</Page>
	);
}

export default PageUsuariosSetupDespachanteDeAduana;
