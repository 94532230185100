// @deprecated: stop exporting, use internally only and use exported defaultFormSubmitHandler outside
export const postForm = form => {
	const parseAPIResponse = response =>
		new Promise(resolve => resolve(response.text()))
			.catch(err =>
				// eslint-disable-next-line prefer-promise-reject-errors
				Promise.reject({
					type: 'NetworkError',
					status: response.status,
					message: err,
				}))
			.then((responseBody) => {
				// Attempt to parse JSON
				try {
					const parsedJSON = JSON.parse(responseBody);
					if (response.ok) return parsedJSON;
					if (response.status >= 500) {
						// eslint-disable-next-line prefer-promise-reject-errors
						return Promise.reject({
							type: 'ServerError',
							status: response.status,
							body: parsedJSON,
						});
					}
					if (response.status <= 501) {
						// eslint-disable-next-line prefer-promise-reject-errors
						return Promise.reject({
							type: 'ApplicationError',
							status: response.status,
							body: parsedJSON,
						});
					}
				} catch (e) {
					// We should never get these unless response is mangled
					// Or API is not properly implemented
					// eslint-disable-next-line prefer-promise-reject-errors
					return Promise.reject({
						type: 'InvalidJSON',
						status: response.status,
						body: responseBody,
					});
				}
			});

	const formData = new FormData(form);
	return fetch(form.action, {
		method: form.getAttribute("method").toUpperCase(),
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			// "Content-Type": "application/json",
		},
		body: new URLSearchParams(formData),
	}).then(parseAPIResponse);
}

export const defaultFormSubmitHandler = settings => {
	const nullFn = () => {};
	const onInit = settings.onInit || nullFn;
	const onSuccess = settings.onSuccess || nullFn;
	const onError = settings.onError || nullFn;
	const setLoading = settings.setLoading || nullFn;
	const setResultMessage = settings.setResultMessage || nullFn;
	const setResultVariant = settings.setResultVariant || nullFn;
	const setButtonDisabled = settings.setButtonDisabled || nullFn;

	return event => {
		event.preventDefault();
		setLoading(true);
		setResultMessage(null);
		setButtonDisabled(true);
		onInit();
		postForm(event.currentTarget)
			.then(response => {
				setLoading(false);
				const message = response.message;
				setResultMessage(message);
				setButtonDisabled(false);
				if (response.success) {
					setResultVariant("success");
					onSuccess(response);
				} else {
					setResultVariant("danger");
					onError(response);
				}
			})
			.catch(err => {
				console.error(err);
				setLoading(false);
				setButtonDisabled(false);
				const message = "Ha ocurrido un error, por favor intenta nuevamente.";
				setResultMessage(message);
				setResultVariant("danger");
				onError({message});
			})
		;
	};
}
