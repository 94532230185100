import React from "react";
import Form from "react-bootstrap/Form";
import { FaComment } from "react-icons/fa";
import TextareaAutosize from 'react-textarea-autosize';
import { useQueryCC, useQueryClient, Loading } from "./ccQuery";
import FriendlyTime from "./FriendlyTime";
import Button from "./Button";
import { defaultFormSubmitHandler } from "../utils";
import { useWsListen } from "../useWebsockets";
import { useUserContext } from "../UserContext";


import "./Chat.scss";

function ChatMessage({ message }) {
	const userContext = useUserContext();
	const isMyMessage = message.usuario._id == userContext.user._id;
	const className = "ChatMessage" + (isMyMessage ? " ChatMessageMine" : "")
	const userName = isMyMessage ? "Yo" : message.usuario.first_name;

	return (
		<div className={className}>
			<span className="ChatMessageText">{ message.text }</span>
			<div className="ChatMessageInfo text-nowrap text-muted">
				<small>{' '}{userName}{' '}&mdash;{' '}<FriendlyTime date={message.created_at} /></small>
			</div>
		</div>
	);
}

function Chat({ id }) {
	const queryClient = useQueryClient()

	const queryKey = React.useMemo(() => ["chat", id], [id]);
	const { isLoading, data, error, isFetching } = useQueryCC(queryKey, () => fetch(`/api/chats/${id}`));

	useWsListen("chat", React.useCallback(chat => {
		if (chat._id == id) {
			queryClient.setQueryData(queryKey, chat);
		}
	}, [id, queryClient, queryKey]));

	const [ sendingMessage, setSendingMessage ] = React.useState(false);
	const [ newText, setNewText ] = React.useState("");

	const onSubmit = defaultFormSubmitHandler({
		setLoading: setSendingMessage,
		onSuccess: () => {
			setNewText("");
		},
		onError: e => {
			console.error(e);
		},
	});

	const messagesContainerEl = React.useRef(null);
	React.useEffect(() => {
		if (messagesContainerEl.current && data && messagesContainerEl.current.lastChild) {
			// FIXME: should only scroll to bottom if it was at the bottom, or the user types a new message
			messagesContainerEl.current.scrollTop = messagesContainerEl.current.scrollHeight;

			// FIXME: la versión con scrollIntoView es animada pero scrollea toda la página, no sólo el wrapper.
			// messagesContainerEl.current.lastChild.scrollIntoView({
			// 	behavior: "smooth",
			// 	block: "end",
			// });
		}
	}, [messagesContainerEl, data]);

	const messages = isLoading || !data ? [] : data.messages;
	return (
		<div className="ChatWrapper my-3">
			<div className="ChatMessages border" ref={messagesContainerEl} >
				{ messages.map(m => <ChatMessage key={m._id} message={m} />) }
			</div>
			<Form action={`/api/chats/${id}/message`} method="post" className="mt-2" onSubmit={onSubmit}>
				<span className="d-flex align-items-end">
					<Form.Control
						as={TextareaAutosize}
						type="text"
						name="text"
						placeholder="enviar un mensaje"
						value={newText}
						onChange={e => setNewText(e.target.value)}
						minRows="1"
						maxRows="5"
						className="mr-2"
						required
					/>
					<Button type="submit" icon={<FaComment />} loading={sendingMessage}>Enviar</Button>
				</span>
			</Form>
		</div>
	);
}

export default Chat;
