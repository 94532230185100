import React from "react";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import { useUserContext } from "../../UserContext";
import BusquedaDespachantePostulacion from "./BusquedaDespachantePostulacion";
import BusquedaDespachantePostularme from "./BusquedaDespachantePostularme";

function BusquedaDespachanteMiPostulacion({ busqueda, postulaciones, onPostulacion, onCancelPostulacion }) {
	const userContext = useUserContext();
	const [miPostulacion, setMiPostulacion] = React.useState(null);

	React.useEffect(() => {
		if (userContext.user) {
			if (postulaciones.length > 0 && postulaciones[0].usuario._id == userContext.user._id) {
				setMiPostulacion(postulaciones[0]);
			} else {
				setMiPostulacion(null);
			}
		}
	}, [userContext, postulaciones]);

	return (
		<Flippy
			flipDirection="horizontal"
			isFlipped={!miPostulacion}
			flipOnClick={false}
		>
			<FrontSide>
				<BusquedaDespachantePostulacion idBusqueda={busqueda._id} postulacion={miPostulacion} onCancelPostulacion={onCancelPostulacion} />
			</FrontSide>
			<BackSide>
				<BusquedaDespachantePostularme busqueda={busqueda} onPostulacion={onPostulacion} />
			</BackSide>
		</Flippy>
	);
}

export default BusquedaDespachanteMiPostulacion;
