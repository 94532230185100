import React from "react";
import { Link, navigate } from "@reach/router";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { toast } from "react-toastify"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaBan, FaBox, FaCalendarAlt, FaCity, FaComment, FaEllipsisV, FaPencilAlt, FaProjectDiagram, FaTrash } from "react-icons/fa";
import ClampLines from 'react-clamp-lines';

import { useUserContext } from "../../UserContext";
import useReferenceTables from "../../data/referenceTables";
import Page from "../../Components/Page";
import { useQueryCC, useQueryClient, Loading } from "../../Components/ccQuery";
import Button from "../../Components/Button";
import FriendlyTime from "../../Components/FriendlyTime";
import BusquedaDespachantePostulaciones from "../../Components/busquedas/BusquedaDespachantePostulaciones.js";
import PageNotFound from "../NotFound.js";


import "./BusquedaDespachante.css";

function PageBusquedaDespachante({ id }) {
	const referenceTables = useReferenceTables();
	const userContext = useUserContext();
	const queryClient = useQueryClient()

	const [ showCloseConfirm, setShowCloseConfirm ] = React.useState(false);
	const [ showDeleteConfirm, setShowDeleteConfirm ] = React.useState(false);
	const [ borrandoBusqueda, setBorrandoBusqueda ] = React.useState(false);
	const [ cerrandoBusqueda, setCerrandoBusqueda ] = React.useState(false);

	const queryKey = ["busquedaDespachante", id];
	const { isLoading, data, error, isFetching } = useQueryCC(queryKey, () => fetch(`/api/busquedas/despachante/ver/${id}`));

	if (error && error.status === 404) {
		return <PageNotFound />;
	}

	if (isLoading || Object.keys(referenceTables).length === 0) {
		return <Loading />;
	}

	const refreshBusqueda = () => {
		queryClient.invalidateQueries(queryKey);
	};
	const busqueda = data ? data.busqueda : null;

	const busquedaPropia = userContext.user && busqueda && busqueda.user_id == userContext.user._id;
	const busquedaIsClosed = busqueda && new Date(busqueda.end_date) < new Date();
	const badgesTexts = busqueda && busqueda.usuario ? [
		busqueda.usuario.is_agente ? "agente" : null,
		busqueda.usuario.is_despachante ? "despachante" : null,
		busqueda.usuario.is_imex ? "imex" : null,
	].filter(v => !!v) : [];
	const badges = badgesTexts.map(v => <Badge key={v} variant="secondary">{v}</Badge>);
	const editUrl = window.location.pathname.replace("/ver/", "/editar/");

	const borrarBusqueda = () => {
		setBorrandoBusqueda(true);
		fetch(`/api/busquedas/despachante/${id}`, { method: "delete" })
			.then(r => r.json())
			.then(r => {
				if (r.success) {
					toast.info("Búsqueda borrada");
					navigate("/dashboard");
				} else {
					throw new Error(r.message);
				}
			})
			.catch(e => {
				setBorrandoBusqueda(false);
				toast.error(e.toString());
			})
	};

	const cerrarBusqueda = () => {
		setCerrandoBusqueda(true);
		fetch(`/api/busquedas/despachante/cerrar/${id}`, { method: "post" })
			.then(r => r.json())
			.then(r => {
				if (r.success) {
					toast.info("Búsqueda cerrada");
					refreshBusqueda();
					setShowCloseConfirm(false);
				} else {
					throw new Error(r.message);
				}
			})
			.catch(e => {
				setCerrandoBusqueda(false);
				toast.error(e);
			})
	};

	const tipo_operaciones = referenceTables.tipo_operaciones.filter(t => busqueda.tipo_operaciones.indexOf(t.id) >= 0);
	const rubros = referenceTables.rubros.filter(t => busqueda.rubros.indexOf(t.id) >= 0);
	const aduanas = referenceTables.aduanas.filter(t => busqueda.aduanas.indexOf(t.id) >= 0);

	return (
		<Page id="PageBuscarDespachante">
			<Page.Title>
				<Breadcrumb>
					<Breadcrumb.Item active>
						{ busquedaPropia ?
							<span className="d-flex justify-content-between align-items-start">
								<span className="mr-2">
									<span className="d-none d-lg-inline">Búsqueda{' '}</span>
									<strong>{busqueda.titulo}</strong>
								</span>
								<Dropdown alignRight>
									<Dropdown.Toggle className="DropdownTogglePlain" variant="secondary"><FaEllipsisV /></Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item as={Link} to={editUrl}><FaPencilAlt />Editar búsqueda...</Dropdown.Item>
										<Dropdown.Item disabled={busquedaIsClosed} onClick={() => {setShowCloseConfirm(true)}}><FaBan />Cerrar búsqueda...</Dropdown.Item>
										<Dropdown.Item onClick={() => {setShowDeleteConfirm(true)}}><FaTrash />Borrar búsqueda...</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</span> :
							"Búsqueda de Despachante de Aduana"
						}
					</Breadcrumb.Item>
				</Breadcrumb>
			</Page.Title>
			<Page.Content>
				<div id="PageBusquedaDespachante">
					<Row>
						<Col lg="5">
							<Card id="PageBusquedaDespachanteDetalles">
								<Card.Body>
									<table className="table table-striped table-sm mw-100">
										<tbody>
											<tr title="Tipo de operación">
												<td>
													<div className="d-flex flex-nowrap align-items-start">
														<FaProjectDiagram className="mr-2 mt-1" />
														<ul>{ tipo_operaciones.map(t => <li key={t.id}>{t.label}</li>)}</ul>
													</div>
												</td>
											</tr>
											<tr title="Rubros">
												<td>
													<div className="d-flex flex-nowrap align-items-start">
														<FaBox className="mr-2 mt-1" />
														<ul>{ rubros.map(t => <li key={t.id}>{t.label}</li>)}</ul>
													</div>
												</td>
											</tr>
											<tr title="Aduanas">
												<td>
													<div className="d-flex flex-nowrap align-items-start">
														<FaCity className="mr-2 mt-1" />
														<ul>{ aduanas.map(t => <li key={t.id}>{t.label}</li>)}</ul>
													</div>
												</td>
											</tr>
											{
												busqueda.comentarios ?
													<tr>
														<td>
															<FaComment className="mr-2" />Comentarios
															<strong>
																<blockquote className="mx-1 my-2">
																	<ClampLines
																		text={busqueda.comentarios}
																		lines={3}
																		ellipsis="..."
																		moreText="ver más"
																		lessText="ver menos"
																	/>
																</blockquote>
															</strong>
														</td>
													</tr>
													: null
											}
										</tbody>
									</table>
									<Card.Text><FaCalendarAlt />{' '}{ busquedaIsClosed ? <Badge variant="warning">Finalizó</Badge> : "Finaliza"} <FriendlyTime date={busqueda.end_date} /> - iniciada <FriendlyTime date={busqueda.created_at} /></Card.Text>
								</Card.Body>
								<Card.Footer>
									<Card.Text><strong>{busqueda.usuario.datos_empresa.razon_social}</strong>{' '}{badges}</Card.Text>
									<Card.Text>Frecuencia de operaciones: <strong>{busqueda.usuario && busqueda.usuario.datos_imex ? busqueda.usuario.datos_imex.frecuencia_operaciones : "N/D"}</strong></Card.Text>
								</Card.Footer>
							</Card>
							<br />
						</Col>

						<Col lg="7">
							<BusquedaDespachantePostulaciones busqueda={busqueda} />
						</Col>
					</Row>
				</div>

				<Modal show={showCloseConfirm} onHide={() => setShowCloseConfirm(false)}>
					<Modal.Header closeButton>
						<Modal.Title>Confirma cerrar esta búsqueda</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<ul>
							<li>Esta operación es irreversible.</li>
							<li>La búsqueda dejará de estar listada para despachantes.</li>
							<li>No podrá postularse ningún nuevo despachante.</li>
							<li>Todos los datos de la búsqueda quedarán en tu perfil.</li>
							<li>Podrás seguir hablando con los despachantes que ya te contestaron.</li>
						</ul>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={() => setShowCloseConfirm(false)} icon={<FaBan />}>Cancelar</Button>
						<Button variant="danger" onClick={cerrarBusqueda} loading={cerrandoBusqueda} icon={<FaTrash />}>Cerrar búsqueda</Button>
					</Modal.Footer>
				</Modal>

				<Modal show={showDeleteConfirm} onHide={() => setShowDeleteConfirm(false)}>
					<Modal.Header closeButton>
						<Modal.Title>Confirma borrar esta búsqueda</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>Al borrar la búsqueda se perderán todos los detalles de la búsqueda como así también todas las respuestas de despachantes. Esto es irreversible.</p>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={() => setShowDeleteConfirm(false)} icon={<FaBan />}>Cancelar</Button>
						<Button variant="danger" onClick={borrarBusqueda} loading={borrandoBusqueda} icon={<FaTrash />}>Borrar búsqueda</Button>
					</Modal.Footer>
				</Modal>
			</Page.Content>
		</Page>
	);
}

export default PageBusquedaDespachante;
