import React from "react";
import Container from "react-bootstrap/Container";
import { FaCog } from "react-icons/fa";
import { useQuery, useQueryClient } from "react-query";

function Loading() {
	return <Container style={{fontSize: "3em", textAlign: "center"}}><FaCog className="icon-spin" /></Container>;
}

class HttpError extends Error {
	constructor(status, message) {
		super(message);
		this.status = status;
	}
}

function useQueryCC(id, fetchFn) {
	return useQuery(id, async () => {
		const fetchResponse = await fetchFn();
		if (fetchResponse.status >= 400) {
			throw new HttpError(fetchResponse.status, fetchResponse.statusText);
		}
		if (!fetchResponse.ok) {
			throw new Error("Network error");
		}

		const response = await fetchResponse.json();
		if (!response.success) {
			throw new Error(response.message);
		}
		return response.data;
	});
}

export {
	useQueryCC,
	useQueryClient,
	Loading,
};
