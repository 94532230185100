import React, { useState, useEffect } from "react";
import { FaInfoCircle, FaSave } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import Alert from "react-bootstrap/Alert";
import Button from "./Button";
import { useUserContext } from "../UserContext";
import useReferenceTables from "../data/referenceTables";
import { defaultFormSubmitHandler } from "../utils.js";


function SetupImex({ onSave }) {
	const referenceTables = useReferenceTables();
	const userContext = useUserContext();
	const [ formState, updateFormState ] = useState({});
	const [ loading, setLoading ] = useState(false);
	const [ message, setMessage ] = useState(null);
	const [ alertVariant, setAlertVariant ] = useState(null);

	const OPTIONS_FRECUENCIA_OPERACIONES = [
		{value: "muy baja", label: "Muy baja - nunca hice / poco frecuente"},
		{value: "baja",     label: "Baja - 1 a 4 operaciones al año"},
		{value: "moderada", label: "Moderada - 1 operación cada 1-2 meses"},
		{value: "alta",     label: "Alta - 2 a 4 operaciones por mes"},
		{value: "muy alta", label: "Muy alta - 5 o más operaciones por mes"},
	];

	useEffect(() => {
		if (userContext.user && Object.keys(referenceTables).length) {
			updateFormState(formState => {
				return {
					...formState,
					frecuencia_operaciones: userContext.user.datos_imex.frecuencia_operaciones,
					rubros: userContext.user.datos_imex.rubros ? referenceTables.rubros.filter(a => userContext.user.datos_imex.rubros.includes(a.id)) : [],
					aduanas: userContext.user.datos_imex.aduanas ? referenceTables.aduanas.filter(a => userContext.user.datos_imex.aduanas.includes(a.id)) : [],
					tipo_operaciones: userContext.user.datos_imex.tipo_operaciones ? referenceTables.tipo_operaciones.filter(a => userContext.user.datos_imex.tipo_operaciones.includes(a.id)) : [],
				};
			});
		}
	}, [userContext, referenceTables]);

	const onChange = event => {
		updateFormState({
			...formState,
			[event.target.name]: event.target.value,
		});
	};

	const onSelectChange = (values, options) => {
		updateFormState({
			...formState,
			[options.name]: typeof values === "object" && values !== null ? values.value : values,
		});
	};

	const onSubmit = defaultFormSubmitHandler({
		setLoading: setLoading,
		setResultMessage: setMessage,
		setResultVariant: setAlertVariant,
		onSuccess: onSave,
	});

	return (
		<Form method="post" action="/api/usuarios/grabar-datos-imex" onChange={onChange} onSubmit={onSubmit}>
			<Alert variant="info" style={{fontSize: "1.1em"}}><FaInfoCircle /> Ingresá tus datos de <strong>Importador/Exportador</strong> para encontrar los despachantes y agentes que mejor se adecúen a tu perfil.</Alert>

			<Form.Group as={Row}>
				<Form.Label column sm="3">Operaciones que solés hacer</Form.Label>
				<Col sm="9">
					<Select
						name="tipo_operaciones"
						delimiter=","
						options={referenceTables.tipo_operaciones}
						value={formState.tipo_operaciones}
						onChange={onSelectChange}
						isMulti
						isSearchable
						placeholder="Elegí al menos una opción"
						closeMenuOnSelect={false}
					/>
				</Col>
			</Form.Group>

			<Form.Group as={Row}>
				<Form.Label column sm="3">Rubros que operás</Form.Label>
				<Col sm="9">
					<Select
						name="rubros"
						delimiter=","
						options={referenceTables.rubros}
						value={formState.rubros}
						onChange={onSelectChange}
						isMulti
						isSearchable
						placeholder="Elegí al menos una opción"
						closeMenuOnSelect={false}
					/>
				</Col>
			</Form.Group>

			<Form.Group as={Row}>
				<Form.Label column sm="3">Aduanas en las que operás</Form.Label>
				<Col sm="9">
					<Select
						name="aduanas"
						delimiter=","
						options={referenceTables.aduanas}
						value={formState.aduanas}
						onChange={onSelectChange}
						isMulti
						isSearchable
						placeholder="Elegí al menos una opción"
						closeMenuOnSelect={false}
					/>
				</Col>
			</Form.Group>

			<Form.Group as={Row}>
				<Form.Label column sm="3">Frecuencia de operaciones</Form.Label>
				<Col sm="9">
					<Select
						name="frecuencia_operaciones"
						value={OPTIONS_FRECUENCIA_OPERACIONES.filter(o => o.value == formState.frecuencia_operaciones)}
						options={OPTIONS_FRECUENCIA_OPERACIONES}
						onChange={onSelectChange}
					/>
				</Col>
			</Form.Group>

			<Row>
				<Col sm="3" />
				<Col sm="9">
					{ message ? (<Alert variant={alertVariant}>{message}</Alert>) : null }

					<Button type="submit" icon={<FaSave />} loading={loading}>Grabar</Button>
				</Col>
			</Row>
		</Form>
	);
}

export default SetupImex;
