import { useState, useEffect } from "react";

let DATA_PROMISE;

function useReferenceTables() {
	let [data, setData] = useState({});

	useEffect(() => {
		if (!DATA_PROMISE) {
			DATA_PROMISE = fetch("/api/tablas-referenciales")
				.then(response => response.json())
				.then(response => {
					const out = response.data;
					Object.keys(out).forEach(k => {
						out[k + "_by_id"] = {};
						out[k].forEach(r => out[k + "_by_id"][r.id] = r.label);
					});
					return out;
				})
		}
		DATA_PROMISE.then(response => {
			setData(response);
		});
	});

	return data;
}

export default useReferenceTables;
