import React from "react";
import { Link } from "@reach/router";
import ScrollAnimation from "react-animate-on-scroll";
import Container from "react-bootstrap/Container";
import Button from "./Button";
import { FaUser } from "react-icons/fa";

import "./PreLaunchBanner.scss";

function PreLaunchBanner() {
	return (
		<div style={{margin: "2em 0"}}>
			<ScrollAnimation animateIn="animate__fadeIn" animateOnce>
				<Container className="PreLaunchBannerWrapper text-center">
					<div className="PreLaunchBannerContent">
						<h2>ETAPA LANZAMIENTO</h2>
						<div style={{fontSize: "1.3em", margin: "1em 0"}}>
							<p>Creá tu perfil y promocioná tu servicio totalmente gratis por 3 meses.</p>
							<Button
								size="lg"
								as={Link}
								icon={<FaUser />}
								to="/login"
								variant="primary"
							>
								Crear cuenta gratis
							</Button>
						</div>
					</div>
				</Container>
			</ScrollAnimation>
		</div>
	);
}

export default PreLaunchBanner;
