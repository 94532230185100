/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-optimization */
import React from "react";
import Container from "react-bootstrap/Container";

class ErrorBoundary extends React.Component {
	// eslint-disable-next-line no-unused-vars
	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	// eslint-disable-next-line no-unused-vars
	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return <Container><h1>Oops... hubo un problema.</h1></Container>;
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
