import React from "react";
import { Link } from "@reach/router";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Page from "../../Components/Page.js";
import UsuarioDatosBasicos from "../../Components/UsuarioDatosBasicos.js";
import { useUserContext } from "../../UserContext";


function PageUsuariosDatosBasicos() {
	const userContext = useUserContext();

	const handleProfileDataSave = userContext.updateUser;

	return (
		<Page narrow>
			<Page.Title showBackButton>
				<Breadcrumb>
					<Breadcrumb.Item linkAs="span"><Link to="/dashboard">Mi Cuenta</Link></Breadcrumb.Item>
					<Breadcrumb.Item active>Datos Básicos</Breadcrumb.Item>
				</Breadcrumb>
			</Page.Title>
			<Page.Content>
				<UsuarioDatosBasicos onSave={handleProfileDataSave} />
			</Page.Content>
		</Page>
	);
}

export default PageUsuariosDatosBasicos;
