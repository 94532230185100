import React from "react";
import { Helmet } from "react-helmet";
import { Link, navigate } from "@reach/router";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import LoginForm from "../../Components/LoginForm.js";
import OAuthButton from "../../Components/OAuthButton";
import { useUserContext } from "../../UserContext";

import "./Login.css";

function PageUsersLogin({ redirectTo }) {
	const userContext = useUserContext();
	if (userContext.user) {
		navigate("/dashboard");
		return null;
	}

	const social_error = new URLSearchParams(window.location.search).has("social_error");

	// <OAuthButton redirectTo={redirectTo} type="yahoo" />{" "}

	return (
		<div className="PaddedPage">
			<Helmet>
				<title>Ingresá o registrate gratis - Conexión COMEX</title>
				<meta name="description" content="Ingresá/registrate gratis en Conexión COMEX" />
			</Helmet>
			<Container id="PageUsersLogin">
				<h4>Ingresá con un click usando tu cuenta de:</h4>
				<br />

				<p className="SocialLoginButtons">
					<OAuthButton redirectTo={redirectTo} type="google" />{" "}
					<OAuthButton redirectTo={redirectTo} type="microsoft" />{" "}
					<OAuthButton redirectTo={redirectTo} type="facebook" />{" "}
				</p>

				{ social_error ? <Alert variant="danger">Ha ocurrido un error. Por favor intenta nuevamente.</Alert> : null }

				<hr />

				<p>O ingresá con tu email y contraseña de Conexión COMEX:</p>
				<LoginForm showRegistrationLink redirectTo={redirectTo} />

				<hr />

				<p className="text-right text-muted text-small"><small>El uso de este sitio implica la aceptación de los <Link to="/terminos-y-condiciones">Términos y Condiciones</Link>.</small></p>

			</Container>
		</div>
	);
}

export default PageUsersLogin;
