import React from "react";
import Container from 'react-bootstrap/Container';
import { Link } from "@reach/router";

function PageUsersCambioEmailNoConfirmado() {
	return (
		<div className="PaddedPage">
			<Container className="NarrowPage">
				<h1>Lo sentimos, pero no pudimos cambiar tu email.</h1>
				<p>Por favor intenta nuevamente. <Link to="/dashboard">Continuar a tu cuenta</Link>.</p>
			</Container>
		</div>
	);
}

export default PageUsersCambioEmailNoConfirmado;
