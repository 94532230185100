import React from "react";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { FaPlus, FaTrash } from "react-icons/fa";
import Button from "./Button";


function TablaGastos({ namePrefix, value, onChange }) {
	onChange = onChange || (() => {});

	const [ rows, setRows ] = React.useState(value || []);

	const addRow = React.useCallback(() => {
		const out = rows.concat({
			concepto: "",
			valor: "",
		});
		setRows(out);
	}, [rows]);

	const deleteRow = React.useCallback(idx => {
		const filtered = rows.filter((r, i) => i !== idx);
		setRows(filtered);
		onChange(filtered);
	}, [rows, onChange]);

	const handleChange = React.useCallback((what, idx) => e => {
		const out = [...rows];
		out[idx][what] = e.target.value;
		setRows(out);
		onChange(out);
	}, [rows, onChange]);

	if (rows.length === 0) {
		addRow();
	}

	return (
		<div className="TablaGastos">
			{ rows.map((r, idx) => (
				<Row key={idx} className="d-flex mb-1">
					<Form.Control
						name={namePrefix + "_concepto[]"}
						type="text"
						placeholder="concepto"
						maxLength="255"
						className="mw-10e mr-1"
						value={r.concepto}
						onChange={handleChange("concepto", idx)}
					/>
					<Form.Control
						name={namePrefix + "_valor[]"}
						type="text"
						placeholder="ej. 100 USD + IVA"
						maxLength="255"
						className="mw-10e mr-1"
						value={r.valor}
						onChange={handleChange("valor", idx)}
					/>
					<Button icon={<FaTrash />} onClick={() => deleteRow(idx)} />
				</Row>
			)) }
			<Button variant="link" icon={<FaPlus />} onClick={addRow}>Agregar ítem</Button>
		</div>
	);
}

export default TablaGastos;
