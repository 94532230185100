import React from "react";
import { io } from "socket.io-client";

const socket = io({
	reconnection: true,
});

// socket.on("connect", () => {
// 	console.log("connected");
// });

// socket.on("disconnect", () => {
// 	console.log("disconnected");
// });

export function useWsListen(eventName, listener) {
	return React.useEffect(() => {
		socket.on(eventName, listener);
		return () => {
			socket.off(eventName, listener);
		};
	}, [eventName, listener]);
}
