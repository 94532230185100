import React, { useState } from "react";
import { FaAt, FaSave } from "react-icons/fa";
import { Link } from "@reach/router";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Page from "../../Components/Page.js";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "../../Components/Button";
import Alert from "react-bootstrap/Alert";
import { useUserContext } from "../../UserContext.js";
import { defaultFormSubmitHandler } from "../../utils.js";

function PageUsuariosCambioEmail() {
	const userContext = useUserContext();
	const [message, setMessage] = useState(null);
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [alertVariant, setAlertVariant] = useState(null);
	const [loading, setLoading] = useState(false);

	const onSubmit = defaultFormSubmitHandler({
		setLoading: setLoading,
		setResultMessage: setMessage,
		setResultVariant: setAlertVariant,
		setButtonDisabled: setButtonDisabled,
	});

	return (
		<Page narrow>
			<Page.Title showBackButton>
				<Breadcrumb>
					<Breadcrumb.Item linkAs="span"><Link to="/dashboard">Mi Cuenta</Link></Breadcrumb.Item>
					<Breadcrumb.Item active>Cambiar Email</Breadcrumb.Item>
				</Breadcrumb>
			</Page.Title>
			<Page.Content>
				<p>Ingresa tu nueva dirección de email. Luego te enviaremos un email a tu nueva casilla para que confirmes el cambio.</p>

				<p>Email actual: {userContext.user.email}</p>

				<Form action="/api/usuarios/cambiar-email" method="post" onSubmit={onSubmit}>
					<InputGroup className="mb-2">
						<InputGroup.Prepend>
							<InputGroup.Text><FaAt /></InputGroup.Text>
						</InputGroup.Prepend>
						<Form.Control
							name="new_email"
							type="email"
							placeholder="Ingresa tu nuevo email"
							required
							maxLength="255"
						/>
					</InputGroup>

					{ message ? (<Alert variant={alertVariant}>{message}</Alert>) : null }

					<div style={{textAlign: "right"}}>
						<p>
							<Button
								icon={<FaSave />}
								variant="primary"
								type="submit"
								disabled={buttonDisabled}
								loading={loading}
							>
								Grabar
							</Button>
						</p>
					</div>
				</Form>
			</Page.Content>
		</Page>
	);
}

export default PageUsuariosCambioEmail;
