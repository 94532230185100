import React from "react";
import Page from "../../Components/Page";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import UsuarioDatosBasicos from "../../Components/UsuarioDatosBasicos.js";


function PageUsuariosCompletarRegistro() {
	const onSave = () => {
		window.location.href = "/dashboard?registro_completo";
	};

	return (
		<Page id="PageUsuariosCompletarRegistro" className="NarrowPage">
			<Page.Title>
				<Breadcrumb>
					<Breadcrumb.Item active>
						Completá tu perfil para continuar{/*  - paso {currentStep}/3*/}
					</Breadcrumb.Item>
				</Breadcrumb>
			</Page.Title>
			<Page.Content>
				<UsuarioDatosBasicos onSave={onSave} />
			</Page.Content>
		</Page>
	);
}

export default PageUsuariosCompletarRegistro;
