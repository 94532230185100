import React from "react";
import Container from 'react-bootstrap/Container';
import { Link } from "@reach/router";

function PageUsersCambioEmailConfirmado() {
	return (
		<div className="PaddedPage">
			<Container className="NarrowPage">
				<h1>Listo, tu email fue actualizado!</h1>
				<p><Link to="/dashboard">Continuar a tu cuenta</Link>.</p>
			</Container>
		</div>
	);
}

export default PageUsersCambioEmailConfirmado;
