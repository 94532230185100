import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { FaArrowDown, FaSearch, FaShip, FaBell, FaReceipt, FaComments, FaProjectDiagram, FaUser } from "react-icons/fa";
import PreLaunchBanner from "../Components/PreLaunchBanner";

import "./PageComoFunciona.scss";

function PageComoFunciona() {
	const [searchType, setSearchType] = useState("despachante");

	const onSearchTypeChange = value => {
		setSearchType(value);
	};

	return (
		<Container className="PaddedPage PageComoFunciona">
			<Helmet>
				<title>Conexión COMEX - Cómo funciona</title>
				<meta name="description" content="Cómo funciona Conexión COMEX" />
			</Helmet>

			<h2 className="text-center">Así funciona <strong className="text-nowrap">Conexión COMEX</strong></h2>

			<br />

			<div className="text-center PageComoFuncionaSteps">
				<p><FaSearch /><span><strong>Importadores y exportadores</strong> buscan:</span></p>

				<div>
					<ToggleButtonGroup name="tipo-busqueda" defaultValue={searchType} onChange={onSearchTypeChange}>
						<ToggleButton value="despachante"><FaProjectDiagram />{' '}Despachante<br />de aduana</ToggleButton>
						<ToggleButton value="agente"><FaShip />{' '}Agente<br />de carga</ToggleButton>
					</ToggleButtonGroup>
				</div>

				{
					searchType === "despachante" ? (
						<>
							<FaArrowDown className="PageComoFuncionaFaArrowDown" />
							<p><FaUser /><span>Se inicia la búsqueda en base al perfil de <strong>importador/exportador</strong></span></p>
							<FaArrowDown className="PageComoFuncionaFaArrowDown" />
							<p><FaBell /><span>Notificamos a los <strong>despachantes</strong> que se especializan en el rubro y se ajustan a los requerimientos</span></p>
							<FaArrowDown className="PageComoFuncionaFaArrowDown" />
							<p><FaReceipt /><span><strong>Despachantes</strong> expresan interés y disponibilidad</span></p>
						</>
						) : (
						<>
							<FaArrowDown className="PageComoFuncionaFaArrowDown" />
							<p><FaShip /><span>Se publican operaciones indicando <strong>origen, destino, mercadería, etc.</strong></span></p>
							<FaArrowDown className="PageComoFuncionaFaArrowDown" />
							<p><FaBell /><span>Notificamos a los <strong>agentes de carga</strong> registrados</span></p>
							<FaArrowDown className="PageComoFuncionaFaArrowDown" />
							<p><FaReceipt /><span><strong>Agentes de carga</strong> envían su presupuesto</span></p>
						</>)
				}
				<FaArrowDown className="PageComoFuncionaFaArrowDown" />
				<p><FaComments /><span>Podés intercambiar <strong>mensajes</strong> y elegir el proveedor que más te gusta</span></p>
			</div>

			<PreLaunchBanner />
		</Container>
	);
}

export default PageComoFunciona;
