import React from "react";
import { Link } from "@reach/router";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Page from "../../Components/Page";
import { useQueryCC, Loading } from "../../Components/ccQuery";
import ListadoBusquedasDespachante from "../../Components/busquedas/ListadoBusquedasDespachante";


function PageBusquedasDespachante() {
	const { data, isLoading, error, isFetching } = useQueryCC("busquedas_despachante", () => fetch("/api/busquedas/despachante-de-aduana"));

	return (
		<Page>
			<Page.Title>
				<Breadcrumb>
					<Breadcrumb.Item active>Búsquedas de Despachante de Aduana</Breadcrumb.Item>
				</Breadcrumb>
			</Page.Title>
			<Page.Content>
				{ isLoading ? <Loading /> : <ListadoBusquedasDespachante busquedas={data} /> }
			</Page.Content>
		</Page>
	);
}

export default PageBusquedasDespachante;
