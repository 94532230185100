import React from "react";
import { Link } from "@reach/router";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FormBusquedaDespachante from "../../Components/busquedas/FormBusquedaDespachante";
import Page from "../../Components/Page";


function PageBuscarDespachante() {
	return (
		<Page id="PageBuscarDespachante">
			<Page.Title showBackButton>
				<Breadcrumb>
					<Breadcrumb.Item linkAs="span"><Link to="/dashboard">Mi Cuenta</Link></Breadcrumb.Item>
					<Breadcrumb.Item active>Nueva Búsqueda de Despachante</Breadcrumb.Item>
				</Breadcrumb>
			</Page.Title>
			<Page.Content>
				<FormBusquedaDespachante />
			</Page.Content>
		</Page>
	);
}

export default PageBuscarDespachante;
