import React from "react";
import PropTypes from "prop-types";
import PageUsersLogin from "./Pages/Users/Login.js";
import ErrorBoundary from "./Components/ErrorBoundary.js";
import { useUserContext } from "./UserContext";

function PrivateRoute({ children }) {
	const userContext = useUserContext();
	if (userContext.loading) {
		return null;
	}
	return (
		<ErrorBoundary>{userContext.user ? children : <PageUsersLogin redirectTo={window.location.href} />}</ErrorBoundary>
	);
}

PrivateRoute.propTypes = {
	children: PropTypes.node.isRequired,
}

export default PrivateRoute;
