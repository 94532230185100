import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PreLaunchBanner from "../Components/PreLaunchBanner";

import "./Planes.scss";

let planes = require("../data/planes.json");

function PagePlanes() {
	// const COTIZACION_DOLAR = 84;
	let [plan, setPlan] = useState("despachante");
	const PLAN = planes[plan];

	return (
		<Container className="PaddedPage">
			<Helmet>
				<title>Conexión COMEX - Planes</title>
				<meta name="description" content="Planes de Conexión COMEX" />
			</Helmet>

			<Row>
				<Col sm="7" className="PagePlanesCoverText">
					<h1>Planes accesibles</h1>
					<ul>
						<li>Publicitá tu negocio a miles de potenciales clientes</li>
						<li>Planes accesibles para pequeños emprendedores y estudios</li>
						<li>Por tiempo limitado, 3 meses de prueba gratis!</li>
					</ul>
				</Col>
				<Col sm="5">
					<Jumbotron className="PagePlanesCover"></Jumbotron>
				</Col>
			</Row>

			<section className="detalle_planes text-center" style={{}}>
				{/* <div className="text-left" style={{margin: "0 auto", maxWidth: "600px"}}>
					<div style={{fontSize: "1.6em", margin: "2em 0"}}>
						<ul>
							<li>Publicitá tu negocio a miles de potenciales clientes</li>
							<li>Planes accesibles para nuevos emprendedores</li>
							<li>Las búsquedas son gratuitas</li>
						</ul>
					</div>
				</div> */}

				{/* <p className="text-center">
					Planes para:{' '}
					<ToggleButtonGroup name="tipo_precios" value={plan} onChange={value => setPlan(value)}>
						<ToggleButton value="despachante"><FaProjectDiagram />{" "}Despachante de Aduana</ToggleButton>
						<ToggleButton value="agente"><FaShip />{" "}Agente de Carga</ToggleButton>
					</ToggleButtonGroup>
				</p> */}

				{/* <CardDeck className="justify-content-center">
					{PLAN.map(item => (
						<Card key={item.name}>
							<Card.Header>{item.name}</Card.Header>
							<Card.Body>
								{item.detail.map(item => (
									<Card.Text key={item.label}>{item.label || ""} <strong>{item.value}</strong></Card.Text>
								))}
							</Card.Body>
							<Card.Footer>
								<Card.Text className="detalle_planes_precio">ARS {Math.floor(item.price_usd * COTIZACION_DOLAR).toLocaleString("ar")}/mes</Card.Text>
								<Card.Text className="detalle_planes_precio">USD {Math.floor(item.price_usd)}/mes</Card.Text>
								<br />
								<Button as={Link} icon={<FaUser />} to="/login" variant="primary">{item.action_button_text}</Button>
							</Card.Footer>
						</Card>
					))}
				</CardDeck> */}
			</section>

			<PreLaunchBanner />
		</Container>
	);
}

export default PagePlanes;
