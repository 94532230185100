import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { FaGoogle, FaFacebook, FaMicrosoft, FaYahoo } from "react-icons/fa";


function OAuthButton({ type, redirectTo }) {
	const [ loading, setLoading ] = useState(false);

	let icon;
	let backgroundColor;
	let text;

	switch (type) {
		case "google":
			icon = <FaGoogle />;
			backgroundColor = "#C93630";
			text = "Google";
			break;
		case "facebook":
			icon = <FaFacebook />;
			backgroundColor = "#1D508F";
			text = "Facebook";
			break;
		case "microsoft":
			icon = <FaMicrosoft />;
			backgroundColor = "#3674AD";
			text = "Microsoft";
			break;
		case "yahoo":
			icon = <FaYahoo />;
			backgroundColor = "#3F266D";
			text = "Yahoo";
			break;
	}

	const onClick = () => {
		setLoading(true);
	};

	const redirect = redirectTo ? "?redirect=" + redirectTo : "";

	return (
		<Button
			icon={icon}
			href={"/auth/" + type + redirect}
			style={{borderColor: "transparent", background: backgroundColor}}
			onClick={onClick}
			loading={loading}
		>
			{text}
		</Button>
	);
}

OAuthButton.propTypes = {
	type: PropTypes.oneOf(["google", "facebook", "microsoft", "yahoo"]),
};

OAuthButton.defaultProps = {
	type: "",
	redirectTo: "",
};


export default OAuthButton;
