import React from "react";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from "react-toastify";
import Button from "../../Components/Button";
import { FaUser } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import { defaultFormSubmitHandler } from "../../utils.js";
import { useUserContext } from "../../UserContext";
import { Link } from "@reach/router";

function BusquedaDespachantePostularme ({ busqueda, onPostulacion }) {
	const id = busqueda._id;
	const razonSocial = busqueda.usuario.datos_empresa.razon_social;

	onPostulacion = onPostulacion ? onPostulacion : () => {};

	const userContext = useUserContext();
	const [ loading, setLoading ] = React.useState(false);
	const [ message, setMessage ] = React.useState(null);
	const [ alertVariant, setAlertVariant ] = React.useState(null);

	const onSubmit = defaultFormSubmitHandler({
		setLoading: setLoading,
		setResultMessage: setMessage,
		setResultVariant: setAlertVariant,
		onSuccess: r => {
			toast.info("Listo, ya te encontrás postulado a esta búsqueda! Te notificaremos cuando te respondan.");
			onPostulacion(r.data.postulacion);
		},
	});

	const email = userContext.user ? userContext.user.datos_empresa.email : null;
	const telefono = userContext.user ? userContext.user.datos_empresa.telefono : null;

	let infoCompartida;
	if (email && telefono) {
		infoCompartida = <>Tu email <strong>{email}</strong> y teléfono <strong>{telefono}</strong> se compartirán automáticamente para que puedan contactarte.</>;
	} else if (email) {
		infoCompartida = <>Tu email <strong>{email}</strong> se compartirá automáticamente para que puedan contactarte.</>;
	} else if (telefono) {
		infoCompartida = <>Tu teléfono <strong>{telefono}</strong> se compartirá automáticamente para que puedan contactarte.</>;
	} else {
		infoCompartida = <>No tienes configurado tu email y teléfono para compartir. <Link to="/mi-perfil/editar/datos-basicos">Configura tus datos de contacto</Link> para que puedan comunicarse contigo.</>;
	}

	return (
		<Card>
			<Card.Header>
				<Card.Title>Postulate a esta búsqueda</Card.Title>
			</Card.Header>
			<Card.Body>
				<Card.Text>Al postularte le enviarás un mensaje a <strong>{razonSocial}</strong> para hacerle saber que estás disponible para manejar sus operaciones.</Card.Text>
				<Form method="post" action={"/api/busquedas/despachante/postularme/" + id } onSubmit={onSubmit}>
					<p>{ infoCompartida }</p>
					<Form.Group>
						<Form.Control as="textarea" name="mensaje" placeholder="Te recomendamos incluir un mensaje personalizado" />
					</Form.Group>

					<Form.Group>
						{ message ? (<Alert variant={alertVariant}>{message}</Alert>) : null }
						<div className="text-right">
							<Button type="submit" loading={loading} icon={<FaUser />}>Postularme</Button>
							<div>
								<small className="text-muted">Tu nombre completo y razón social se mostrarán al postularte.</small>
							</div>
						</div>
					</Form.Group>
				</Form>
			</Card.Body>
		</Card>
	);
}

export default BusquedaDespachantePostularme;
