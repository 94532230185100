import React from "react";
import { Link } from "@reach/router";
import CardDeck from "react-bootstrap/CardDeck";
import Card from "react-bootstrap/Card";
import Button from "../Components/Button";
import PreLaunchBanner from "../Components/PreLaunchBanner";

import { FaShip, FaGlobe, FaProjectDiagram, FaUser, FaSearch } from "react-icons/fa";

import Container from "react-bootstrap/Container"

// import Jumbotron from "react-bootstrap/Jumbotron";
// import { Parallax } from "react-parallax";

import "./Homepage.scss";

function PageHomepage() {
	// const onServicesClick = event => {
	// 	event.preventDefault();
	// 	const target = new URL(event.currentTarget.href).hash.substring(1);
	// 	const el = document.getElementById(target);
	// 	window.scrollTo({
	// 		top: el.getBoundingClientRect().top + window.pageYOffset - 64,
	// 		behavior: "smooth"
	// 	});
	// };

	return (
		<section className="Homepage">
			<div className="Homepage_MainBanner">
				<Container>
					<div className="Homepage_MainBannerText">
						<h1>Conectando operadores <br /> de Comercio Exterior</h1>
					</div>
				</Container>
			</div>

			<div id="Homepage_Service_Index">
				<Container>
					<CardDeck>
						<Card>
							<Card.Header>
								<FaProjectDiagram />
								Despachantes de Aduana
							</Card.Header>
							<Card.Body>
								<ul>
									<li>Ofrecé tu servicio a miles de potenciales clientes.</li>
									<li>Perdés menos tiempo - filtramos las operaciones que no te interesan.</li>
									<li>Encontrá corresponsales en otras aduanas del país.</li>
								</ul>
							</Card.Body>
							<Card.Footer>
								<Button
									as={Link}
									to="/login"
									icon={<FaUser />}
									className="action-button"
								>
									Registrate gratis
								</Button>
							</Card.Footer>
						</Card>

						<Card>
							<Card.Header>
								<FaShip />Agentes de Carga
							</Card.Header>
							<Card.Body>
								<ul>
									<li>Publicitá tu servicio entre miles de potenciales clientes.</li>
									<li>Registrate y recibí notificaciones de operaciones pendientes de cotización.</li>
									<li>Contestá dentro del plazo estipulado y accedé a más clientes.</li>
								</ul>
							</Card.Body>
							<Card.Footer>
								<Button
									as={Link}
									to="/login"
									icon={<FaUser />}
									className="action-button"
								>
									Registrate gratis
								</Button>
							</Card.Footer>
						</Card>
						<Card>
							<Card.Header><FaGlobe />Importadores / Exportadores</Card.Header>
							<Card.Body>
								<ul>
									<li>Encontramos los profesionales más adecuados para tus operaciones.</li>
									<li>Accedé a múltiples cotizaciones para encontrar las mejores tarifas y servicios en fletes internacionales.</li>
									<li>Las búsquedas son 100% gratuitas.</li>
								</ul>
							</Card.Body>
							<Card.Footer>
								<Button
									as={Link}
									to="/como-funciona"
									icon={<FaSearch />}
									className="action-button"
								>
									¿Cómo funciona?
								</Button>
							</Card.Footer>
						</Card>
					</CardDeck>
				</Container>
			</div>

			<p><br /></p>

			<PreLaunchBanner />
		</section>
	);
}

export default PageHomepage;
