import React from "react";
import { FaPhoneAlt, FaUserTie, FaBuilding, FaAt } from "react-icons/fa";

function DatosUsuarioOferta({ usuario }) {
	if (!usuario) {
		return null;
	}

	const contactInfo = [];
	if (usuario.datos_empresa.telefono) {
		contactInfo.push(<span key={2}><a href={`tel:${usuario.datos_empresa.telefono}`}><FaPhoneAlt />{usuario.datos_empresa.telefono}</a></span>);
	}
	if (usuario.datos_empresa.email) {
		contactInfo.push(<a key={1} href={`mailto:${usuario.datos_empresa.email}`}><FaAt />{usuario.datos_empresa.email}</a>);
	}

	return (
		<div>
			<div className="h6" style={{lineHeight: "1em"}}>
				<span className="d-flex align-items-start">
					<FaUserTie className="mr-2" />
					{usuario.first_name}{' '}{usuario.last_name}
				</span>

				<span className="mx-3"></span>

				<span className="d-flex align-items-start">
					<FaBuilding className="mr-2" />
					{usuario.datos_empresa.razon_social}
				</span>
			</div>

			<div className="d-flex flex-wrap align-items-center my-3 h6">
				{contactInfo.map((c, idx) => <div key={idx} className="d-flex align-items-center mr-3">{c}</div>)}
			</div>
		</div>
	);
}

export default DatosUsuarioOferta;
