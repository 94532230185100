import React from "react";
import { Link } from "@reach/router";
import Button from "../../Components/Button";
import Relevancia from "../../Components/Relevancia";
import {FaBox, FaSignOutAlt, FaSignInAlt, FaPlane, FaShip, FaTruck} from "react-icons/fa";
import FriendlyTime from "../FriendlyTime";
import Badge from "react-bootstrap/esm/Badge";
import useReferenceTables from "../../data/referenceTables";
import { Loading } from "../ccQuery";
import { useUserContext } from "../../UserContext";

function BusquedaAgenteTeaser({ busqueda }) {
	const userContext = useUserContext();
	const referenceTables = useReferenceTables();
	if (!Object.keys(referenceTables).length) {
		return <Loading />;
	}

	const now = new Date();
	const is_closed = new Date(busqueda.end_date) < now;

	const via = referenceTables.agente_via_by_id[busqueda.via] || "";

	let viaIcon;
	if (busqueda.via == "aerea") {
		viaIcon = <FaPlane />;
	} else if (busqueda.via == "maritima") {
		viaIcon = <FaShip />;
	} else if (busqueda.via == "terrestre") {
		viaIcon = <FaTruck />;
	}

	return (
		<Button
			variant="light"
			as={Link}
			to={busqueda.url}
			className="d-flex flex-wrap flex-md-nowrap align-items-start justify-content-between border-primary"
			style={{maxWidth: "700px"}}
		>
			<div className="text-left mx-1 my-1 flex-shrink-1" style={{maxWidth: "calc(min(100%, 500px)"}}>
				<div className="text-truncate text-nowrap" title="Mercadería">
					<span title={ "Vía " + via }>{viaIcon}</span>
					{' '}
					<strong>{ busqueda.mercaderia }</strong>
				</div>
				<div className="text-truncate text-nowrap" title="Origen"><FaSignOutAlt />{' '}<strong>{ busqueda.lugar_origen }</strong></div>
				<div className="text-truncate text-nowrap" title="Destino"><FaSignInAlt />{' '}<strong>{ busqueda.lugar_destino }</strong></div>
			</div>
			<div className="text-right mx-1 my-1">
				<div><Badge>Creada <FriendlyTime date={busqueda.created_at} /></Badge></div>
				<div><Badge className={is_closed ? "text-danger" : ""}>{ is_closed ? "Finalizó" : "Finaliza"}{' '}<FriendlyTime date={busqueda.end_date} /></Badge></div>
				<div><Relevancia data={busqueda.relevancia} /></div>
			</div>
		</Button>
	);

}

export default BusquedaAgenteTeaser;
