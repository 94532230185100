import React from "react";
import Container from "react-bootstrap/Container";
import Badge from "react-bootstrap/Badge";

import "./Users.scss";

function PageAdminUsers() {
	const [users, setUsers] = React.useState([]);

	React.useEffect(() => {
		fetch("/api/admin/usuarios/lista")
			.then(async (response) => {
				const users = await response.json();
				setUsers(users);
			})
	}, []);

	return (
		<Container className="PageAdminUsers">
			<h1>{users.length} Usuarios</h1>

			{
				users.map(u => (
					<div key={u._id}>
						<Badge variant="">{u._id}</Badge>
						{`${u.first_name} ${u.last_name} <${u.email}>`}
						<Badge variant="info">{u.created_at.substring(0,10).split("-").reverse().join("/")}</Badge>
						{!u.finished_registration ? <Badge variant="warning">no terminó registro</Badge> : null}
						{u.is_agente ? <Badge variant="secondary">agente</Badge> : null}
						{u.is_despachante ? <Badge variant="secondary">despachante</Badge> : null}
						{u.is_imex ? <Badge variant="secondary">imex</Badge> : null}
					</div>
				))
			}
		</Container>
	);
}

export default PageAdminUsers;
