import React from "react";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import { FaPhoneAlt, FaEllipsisV, FaUserTie, FaBuilding, FaTrash, FaUser, FaAt, FaComments } from "react-icons/fa";
import Button from "../Button";
import DatosUsuarioOferta from "../DatosUsuarioOferta";
import Chat from "../Chat";
import FriendlyTime from "../FriendlyTime";
import { useUserContext } from "../../UserContext";
import { RelevanciaItems } from "../Relevancia";
import {SlideDown} from "react-slidedown";
import "react-slidedown/lib/slidedown.css";


function BusquedaDespachantePostulacion({ idBusqueda, postulacion, onCancelPostulacion }) {
	onCancelPostulacion = onCancelPostulacion ? onCancelPostulacion : () => {};
	const userContext = useUserContext();
	const [ showCancelConfirmation, setShowCancelConfirmation] = React.useState(false);
	const [ cancelling, setCancelling] = React.useState(false);
	const [ chatOpen, setChatOpen ] = React.useState(false);
	const [ chatToggleText, setChatToggleText ] = React.useState("Abrir chat");

	const onChatToggleClick = React.useMemo(() => {
		return () => {
			setChatToggleText(chatOpen ? "Abrir chat" : "Cerrar chat");
			setChatOpen(!chatOpen);
		};
	}, [ chatOpen ]);


	if (!postulacion) {
		return null;
	}

	const es_mi_postulacion = userContext.user && userContext.user._id == postulacion.usuario._id;

	const onCancelarPostulacionClick = () => {
		setShowCancelConfirmation(true);
	};

	const cancelarPostulacion = () => {
		setCancelling(true);
		fetch(`/api/busquedas/despachante/borrar-postulacion/${idBusqueda}`, {method: "post"})
			.then(() => {
				setShowCancelConfirmation(false);
				onCancelPostulacion();
			})
			.catch(e => {
				console.error(e);
			})
			.then(() => {
				setCancelling(false);
			});
	};


	return (
		<>
			<Card className="BusquedaDespachantePostulacion">
				<Card.Body>
					<span className="d-flex justify-content-between">
						<span>
							<DatosUsuarioOferta usuario={postulacion.usuario} />
						</span>
						<span>
							{
								es_mi_postulacion
								? <Dropdown alignRight>
										<Dropdown.Toggle className="DropdownTogglePlain" variant="light"><FaEllipsisV /></Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item onClick={onCancelarPostulacionClick}><FaTrash />Cancelar mi postulación...</Dropdown.Item>
										</Dropdown.Menu>
								</Dropdown>
								: null
							}
						</span>
					</span>

					<div className="d-flex flex-wrap justify-content-between align-items-start">
						<div>
							<p>
								<small className="text-muted font-italic">
									{ postulacion.created_by == "system" ? "Sugerido por la plataforma" : "Se postuló" }
									<span className="ml-1"><FriendlyTime date={postulacion.created_at} as-suffix /></span>
								</small>
							</p>
							<RelevanciaItems data={postulacion.relevancia} />
						</div>

						<Button onClick={onChatToggleClick}><FaComments />{chatToggleText}</Button>
					</div>

					<SlideDown>
						{ chatOpen ? <Chat id={postulacion.chat_id} /> : null }
					</SlideDown>
				</Card.Body>
			</Card>

			<Modal show={showCancelConfirmation} onHide={() => setShowCancelConfirmation(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Confirma la cancelación</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Una vez que canceles tu postulación no aparecerás más en esta búsqueda. Podrás volver a postularte si te arrepentís.</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={() => setShowCancelConfirmation(false)} icon={<FaUser />}>Dejar mi postulación</Button>
					<Button variant="danger" onClick={cancelarPostulacion} loading={cancelling} icon={<FaTrash />}>Borrar mi postulación</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default BusquedaDespachantePostulacion;
