import React from "react";
import { Link, navigate } from "@reach/router";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { toast } from "react-toastify"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaBan, FaCalendarAlt, FaEllipsisV, FaPencilAlt, FaTrash } from "react-icons/fa";

import { useUserContext } from "../../UserContext";
import Page from "../../Components/Page";
import { useQueryCC, Loading, useQueryClient } from "../../Components/ccQuery";
import Button from "../../Components/Button";
import BusquedaAgenteDetalles from "../../Components/BusquedaAgenteDetalles";
import FriendlyTime from "../../Components/FriendlyTime";
import BusquedaAgenteCotizaciones from "../../Components/busquedas/BusquedaAgenteCotizaciones.js";
import PageNotFound from "../NotFound.js";


function PageBusquedaAgente({ id }) {
	const userContext = useUserContext();
	const queryClient = useQueryClient();

	const [ showCloseConfirm, setShowCloseConfirm ] = React.useState(false);
	const [ showDeleteConfirm, setShowDeleteConfirm ] = React.useState(false);
	const [ borrandoBusqueda, setBorrandoBusqueda ] = React.useState(false);
	const [ cerrandoBusqueda, setCerrandoBusqueda ] = React.useState(false);

	const queryKey = ["busquedaAgente", id];
	const { isLoading, data, error, isFetching } = useQueryCC(queryKey, () => fetch(`/api/busquedas/agente/ver/${id}`));

	const busqueda = React.useMemo(() => data ? data.busqueda : null, [data]);

	if (error && error.status === 404) {
		return <PageNotFound />;
	}

	if (isLoading) {
		return <Loading />;
	}

	const refreshBusqueda = () => {
		queryClient.invalidateQueries(queryKey);
	};
	const busquedaPropia = userContext.user && busqueda && busqueda.user_id == userContext.user._id;
	const busquedaIsClosed = busqueda && new Date(busqueda.end_date) < new Date();
	const editUrl = window.location.pathname.replace("/ver/", "/editar/");

	const borrarBusqueda = () => {
		setBorrandoBusqueda(true);
		fetch(`/api/busquedas/agente/${id}`, { method: "delete" })
			.then(r => r.json())
			.then(r => {
				if (r.success) {
					toast.info("Búsqueda borrada");
					navigate("/dashboard");
				} else {
					throw new Error(r.message);
				}
			})
			.catch(e => {
				toast.error(e.toString());
			})
			.then(() => {
				setBorrandoBusqueda(false);
			})
	};

	const cerrarBusqueda = () => {
		setCerrandoBusqueda(true);
		fetch(`/api/busquedas/agente/cerrar/${id}`, { method: "put" })
			.then(r => r.json())
			.then(r => {
				if (r.success) {
					toast.info("Búsqueda cerrada");
					refreshBusqueda();
					setShowCloseConfirm(false);
				} else {
					throw new Error(r.message);
				}
			})
			.catch(e => {
				toast.error(e.toString());
			})
			.then(() => {
				setCerrandoBusqueda(false);
			})
	};

	return (
		<Page id="PageBuscarAgente">
			<Page.Title>
				<Breadcrumb>
					<Breadcrumb.Item active>
						{ busquedaPropia ?
							<span className="d-flex justify-content-between align-items-start">
								<span className="mr-2">
									<span className="d-none d-lg-inline">Búsqueda{' '}</span>
									<strong>{busqueda.titulo}</strong>
								</span>
								<Dropdown alignRight>
									<Dropdown.Toggle className="DropdownTogglePlain" variant="secondary"><FaEllipsisV /></Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item as={Link} to={editUrl}><FaPencilAlt />Editar búsqueda...</Dropdown.Item>
										<Dropdown.Item disabled={busquedaIsClosed} onClick={() => {setShowCloseConfirm(true)}}><FaBan />Cerrar búsqueda...</Dropdown.Item>
										<Dropdown.Item onClick={() => {setShowDeleteConfirm(true)}}><FaTrash />Borrar búsqueda...</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</span>
							: "Búsqueda de Agente de Carga"
						}
					</Breadcrumb.Item>
				</Breadcrumb>
			</Page.Title>
			<Page.Content>
				<div id="PageBusquedaAgente">
					<Row>
						<Col lg="5">
							<Card>
								<Card.Body>
									<BusquedaAgenteDetalles busqueda={busqueda} />
									<Card.Text><FaCalendarAlt />{' '}{ busquedaIsClosed ? <Badge variant="warning">Finalizó</Badge> : "Finaliza"} <FriendlyTime date={busqueda.end_date} /> - iniciada <FriendlyTime date={busqueda.created_at} /></Card.Text>
								</Card.Body>
								<Card.Footer>
									<Card.Text><strong>{busqueda.usuario.datos_empresa.razon_social}</strong></Card.Text>
								</Card.Footer>
							</Card>
							<br />
						</Col>

						<Col lg="7">
							<BusquedaAgenteCotizaciones busqueda={busqueda} onChange={refreshBusqueda} />
						</Col>
					</Row>
				</div>

				<Modal show={showCloseConfirm} onHide={() => setShowCloseConfirm(false)}>
					<Modal.Header closeButton>
						<Modal.Title>Confirma cerrar esta búsqueda</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<ul>
							<li>Esta operación es irreversible.</li>
							<li>La búsqueda dejará de estar listada para Agentes.</li>
							<li>No podrán enviarte nuevas cotizaciones.</li>
							<li>Todos los datos de la búsqueda quedarán en tu perfil.</li>
							<li>Podrás seguir hablando con los Agentes que ya te contestaron.</li>
						</ul>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={() => setShowCloseConfirm(false)} icon={<FaBan />}>Cancelar</Button>
						<Button variant="danger" onClick={cerrarBusqueda} loading={cerrandoBusqueda} icon={<FaTrash />}>Cerrar búsqueda</Button>
					</Modal.Footer>
				</Modal>
				<Modal show={showDeleteConfirm} onHide={() => setShowDeleteConfirm(false)}>
					<Modal.Header closeButton>
						<Modal.Title>Confirma borrar esta búsqueda</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>Al borrar la búsqueda se perderán todos los detalles de la búsqueda como así también todas las respuestas de Agentes. Esto es irreversible.</p>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={() => setShowDeleteConfirm(false)} icon={<FaBan />}>Cancelar</Button>
						<Button variant="danger" onClick={borrarBusqueda} loading={borrandoBusqueda} icon={<FaTrash />}>Borrar búsqueda</Button>
					</Modal.Footer>
				</Modal>
			</Page.Content>
		</Page>
	);
}

export default PageBusquedaAgente;
