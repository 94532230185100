import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { toast } from "react-toastify";
import { FaUser, FaComments, FaList, FaEllipsisV, FaTrash } from "react-icons/fa";
import Button from "../Button";
import FriendlyTime from "../FriendlyTime";
import Chat from "../Chat";
import DetalleCotizacionAgente from "./DetalleCotizacionAgente";
import DatosUsuarioOferta from "../DatosUsuarioOferta";
import SlideDown from "react-slidedown";

import { useUserContext } from "../../UserContext";


function CotizacionAgente({ cotizacion, onCancelCotizacion, onEdit }) {
	onEdit = onEdit ? onEdit : () => {};
	onCancelCotizacion = onCancelCotizacion ? onCancelCotizacion : () => {};

	const userContext = useUserContext();
	const [ verDetalle, setVerDetalle ] = React.useState(false);
	const [ showCancelConfirmation, setShowCancelConfirmation] = React.useState(false);
	const [ cancelling, setCancelling] = React.useState(false);
	const [ chatOpen, setChatOpen ] = React.useState(false);
	const [ chatToggleText, setChatToggleText ] = React.useState("Abrir chat");

	const onVerDetalleClick = React.useCallback(() => {
		setVerDetalle(!verDetalle);
	}, [ verDetalle ]);


	const es_mi_cotizacion = userContext.user && userContext.user._id == cotizacion.usuario._id;

	const onCancelarCotizacionClick = () => {
		setShowCancelConfirmation(true);
	};

	const onEditarCotizacionClick = () => {
		setVerDetalle(false);
		setChatOpen(false);
		setChatToggleText("Abrir chat");
		onEdit();
	};

	const cancelarCotizacion = () => {
		setCancelling(true);
		fetch(`/api/busquedas/agente/cotizacion/${cotizacion._id}`, {method: "delete"})
			.then(r => r.json())
			.then(r => {
				if (r.success) {
					toast.info("Cotización borrada");
					onCancelCotizacion();
				} else {
					throw new Error(r.message);
				}
			})
			.catch(e => {
				toast.error(e.toString());
			})
			.then(() => {
				setShowCancelConfirmation(false);
				setCancelling(false);
			});
	};

	const onChatToggleClick = React.useMemo(() => {
		return () => {
			setChatToggleText(chatOpen ? "Abrir chat" : "Cerrar chat");
			setChatOpen(!chatOpen);
		};
	}, [ chatOpen ]);

	if (!cotizacion) {
		return null;
	}

	return (
		<>
			<Card>
				<Card.Body>
					<span className="d-flex justify-content-between">
						<div>
							<div className="d-flex flex-wrap align-items-center">
								<span className="text-nowrap mr-3">Frecuencia <strong>{ cotizacion.frecuencia_servicio }</strong></span>
								<span className="text-nowrap mr-3">Total <strong>{ cotizacion.costo_total }</strong></span>
								<Button onClick={onVerDetalleClick} icon={<FaList />} className="my-1">Detalle</Button>
							</div>
							<div className="text-muted"><small>cotización generada{' '}<FriendlyTime date={cotizacion.created_at} /></small></div>
						</div>

						<span>
							{
								es_mi_cotizacion
								? <Dropdown alignRight>
										<Dropdown.Toggle className="DropdownTogglePlain" variant="light"><FaEllipsisV /></Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item onClick={onEditarCotizacionClick}><FaTrash />Editar cotización...</Dropdown.Item>
											<Dropdown.Item onClick={onCancelarCotizacionClick}><FaTrash />Cancelar cotización...</Dropdown.Item>
										</Dropdown.Menu>
								</Dropdown>
								: null
							}
						</span>
					</span>

					<SlideDown className="small">{ verDetalle ? <DetalleCotizacionAgente cotizacion={cotizacion} /> : null }</SlideDown>
				</Card.Body>
				<Card.Footer>
					<div className="d-flex justify-content-between flex-wrap">
						<DatosUsuarioOferta usuario={cotizacion.usuario} />
						<Button onClick={onChatToggleClick} icon={<FaComments />} className="align-self-end text-nowrap">{chatToggleText}</Button>
					</div>
					<SlideDown>{ chatOpen ? <Chat id={cotizacion.chat_id} /> : null }</SlideDown>
				</Card.Footer>
			</Card>

			<Modal show={showCancelConfirmation} onHide={() => setShowCancelConfirmation(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Confirma la cancelación</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>¿Estás seguro de querer cancelar tu cotización? Podés volver a cotizar luego si te arrepentís pero deberás ingresar todos los datos nuevamente.</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={() => setShowCancelConfirmation(false)} icon={<FaUser />}>Dejar mi cotización</Button>
					<Button variant="danger" onClick={cancelarCotizacion} loading={cancelling} icon={<FaTrash />}>Borrar mi cotización</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default CotizacionAgente;
