import React, { useState } from "react";
import { FaUnlock } from "react-icons/fa";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "../../Components/Button";
import { postForm } from "../../utils.js";
import { useUserContext } from "../../UserContext";

function PageUsersOlvidoContrasena() {
	const userContext = useUserContext();
	const [message, setMessage] = useState(null);
	const [messageVariant, setMessageVariant] = useState(null);
	const [loading, setLoading] = useState(false);

	const onFormSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();

		setMessage(null);
		setMessageVariant(null);
		setLoading(true);
		postForm(event.currentTarget)
			.then(response => {
				setLoading(false);
				if (response.success) {
					setMessage("Listo! Te enviamos un email para que blanquees tu contraseña.");
					setMessageVariant("success");
				} else {
					setMessage(response.message);
					setMessageVariant("danger");
				}
			})
			.catch(err => {
				console.error(err);
				setLoading(false);
				setMessage("Ocurrió un error, por favor intenta nuevamente.");
				setMessageVariant("danger");
		});
	};


	return (
		<div className="PaddedPage">
			<Container className="NarrowPage">
				<h1>Olvidaste tu contraseña?</h1>
				<p>No hay problema! Ingresá tu email y te enviaremos un link para blanquearla:</p>
				<Form action="/api/usuarios/pedir-blanqueo-contrasena" method="post" onSubmit={onFormSubmit}>
					<InputGroup className="mb-2">
						<InputGroup.Prepend>
							<InputGroup.Text>@</InputGroup.Text>
						</InputGroup.Prepend>
						<Form.Control
							name="email"
							type="email"
							placeholder="Email"
							defaultValue={userContext.user ? userContext.user.email : null}
							required
							maxLength="255"
						/>
					</InputGroup>

					{message ? (<Alert variant={messageVariant}>{message}</Alert>) : null}

					<div style={{textAlign: "right"}}>
						<p>
							<Button icon={<FaUnlock />} variant="primary" type="submit" loading={loading}>Continuar</Button>
						</p>
					</div>
				</Form>
			</Container>
		</div>
	);
}

export default PageUsersOlvidoContrasena;
