import React from "react";
import Button from "./Button";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";

import "./Page.css";
import { FaChevronLeft } from "react-icons/fa";

function Page({ children, narrow, ...otherProps }) {
	const className = "Page" + (narrow ? " PageNarrow" : "");
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Container className={className} {...otherProps}>
			{ children }
		</Container>
	);
}

function PageTitle({ children, showBackButton, onBackClick }) {
	const backClickCallback = onBackClick || (() => window.history.back());
	const backButton = <Button className="PageTitleGoBackButton" icon={<FaChevronLeft />} variant="secondary" onClick={backClickCallback} />
	return (
		<h1 className="PageTitle">
			{ showBackButton ? backButton : null}
			{ children }
		</h1>
	);
}

function PageContent({ children }) {
	return <section>{ children }</section>;
}

Page.Title = PageTitle;
Page.Content = PageContent;

Page.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	children: PropTypes.any.isRequired,
};

Page.defaultProps = {
};

export default Page;
