import React from "react";
import Container from 'react-bootstrap/Container';
import { Link } from '@reach/router';

function PageUsersRegistroNoConfirmado() {
    return (
        <Container>
            <h1>No pudimos confirmar tu cuenta.</h1>
            <p>El código de confirmación no coincide con ninguna cuenta.</p>
            <p>Si crees que se trata de un error, no dudes en ponerte en <Link to="/contacto">contacto</Link> con nosotros.</p>
        </Container>
    );
}

export default PageUsersRegistroNoConfirmado;
