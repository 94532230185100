import React, { useState } from "react";
import { FaInfoCircle, FaSave } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "./Button";
import { useUserContext } from "../UserContext";
import { defaultFormSubmitHandler } from "../utils.js";

function SetupAgenteDeCarga({ onSave }) {
	const userContext = useUserContext();

	const [ formState, updateFormState ] = useState(
		userContext.user ? {
			// cuit: userContext.user.info_agente.cuit,
			// razon_social: userContext.user.info_agente.razon_social,
			// email: userContext.user.info_agente.email,
			// telefono: userContext.user.info_agente.telefono,
		} :
		{}
	);

	const [ loading, setLoading ] = useState(false);
	const [ message, setMessage ] = useState(null);
	const [ alertVariant, setAlertVariant ] = useState(null);

	const onChange = event => {
		updateFormState({
			...formState,
			[event.target.name]: event.target.value,
		})
	};

	const onSubmit = defaultFormSubmitHandler({
		setLoading: setLoading,
		setResultMessage: setMessage,
		setResultVariant: setAlertVariant,
		onSuccess: onSave,
	});

	return (
		<Form method="post" action="/api/usuarios/grabar-datos-agente" onChange={onChange} onSubmit={onSubmit}>
			<Alert variant="info" style={{fontSize: "1.1em"}}><FaInfoCircle /> Ingresá tus datos de <strong>Agente de Carga</strong> para aparecer en las búsquedas de posibles clientes.</Alert>

			<Form.Group as={Row}>
				<Form.Label column md="3">CUIT</Form.Label>
				<Col md="9">
					<Form.Control
						required
						name="cuit"
						type="text"
						placeholder=""
						defaultValue={formState.cuit}
						maxLength="13"
						pattern="[0-9]{2}[\- ]?[0-9]{8}[\- ]?[0-9]"
					/>
				</Col>
			</Form.Group>

			<Form.Group as={Row}>
				<Form.Label column md="3">Razón Social</Form.Label>
				<Col md="9">
					<Form.Control
						required
						name="razon_social"
						type="text"
						placeholder=""
						defaultValue={formState.razon_social}
						maxLength="255"
					/>
				</Col>
			</Form.Group>

			<Form.Group as={Row}>
				<Form.Label column md="3">Email</Form.Label>
				<Col md="9">
					<Form.Control
						required
						name="email"
						type="email"
						placeholder=""
						defaultValue={formState.email}
						maxLength="255"
					/>
				</Col>
			</Form.Group>

			<Form.Group as={Row}>
				<Form.Label column md="3">Teléfono</Form.Label>
				<Col md="9">
					<Form.Control
						required
						name="telefono"
						type="text"
						placeholder=""
						defaultValue={formState.telefono}
						maxLength="50"
					/>
				</Col>
			</Form.Group>

			<Row>
				<Col md="3" />
				<Col md="9">
					{ message ? (<Alert variant={alertVariant}>{message}</Alert>) : null }

					<Button type="submit" icon={<FaSave />} loading={loading}>Grabar</Button>
				</Col>
			</Row>
		</Form>
	);
}

export default SetupAgenteDeCarga;
