import React from "react";
import BusquedaAgenteTeaser from "./BusquedaAgenteTeaser";
import useReferenceTables from "../../data/referenceTables";
import { Loading } from "../ccQuery";


function ListadoBusquedasAgente({ busquedas }) {
	const referenceTables = useReferenceTables();
	if (!Object.keys(referenceTables).length) {
		return <Loading />;
	}
	return (
		<>
			{ busquedas.length === 0
				? "En este momento no hay búsquedas de agente abiertas que coincidan con tu perfil."
				: busquedas.map(b => (
					<p key={b._id}><BusquedaAgenteTeaser busqueda={b} /></p>
				))}
		</>
	);
}

export default ListadoBusquedasAgente;
