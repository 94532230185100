import React from "react";
import Card from "react-bootstrap/Card";
import { FaSearch, FaProjectDiagram, FaShip, FaInfoCircle } from "react-icons/fa";
import { useUserContext } from "../../UserContext";
import { Link } from "@reach/router";
import { Loading, useQueryCC } from "../ccQuery";
import Alert from "react-bootstrap/esm/Alert";
import Button from "../Button";


function DashboardBusquedasMisMatches() {
	const userContext = useUserContext();
	const { data: dataDespachantes, isLoading: isLoadingDespachantes, error, isFetching } = useQueryCC("busquedas_despachante", () => fetch("/api/busquedas/despachante-de-aduana"));
	const { data: dataAgentes, isLoading: isLoadingAgentes, errorAgentes, isFetchingAgentes } = useQueryCC("busquedas_agente", () => fetch("/api/busquedas/agente-de-carga"));

	if (isLoadingDespachantes || isLoadingAgentes) {
		return <Loading />;
	}

	const busquedasDespachanteCount = dataDespachantes.length;
	const busquedasAgenteCount = dataAgentes.length;

	return (
		<Card>
			<Card.Header as="h5">
				<span className="mr-3"><FaSearch />{' '}Búsquedas que te pueden interesar</span>
			</Card.Header>
			<Card.Body>
				{ userContext.user.is_despachante && !Object.entries(userContext.user.datos_despachante).length
					? <Alert variant="warning"><FaInfoCircle />Completá tus datos de <Link to="/mi-perfil/editar/datos-despachante">Despachante de Aduana</Link> para aparecer en más búsquedas.</Alert>
					: null }

				{ userContext.user.is_imex && !Object.entries(userContext.user.datos_imex).length
					? <Alert variant="warning"><FaInfoCircle />Completá tus datos de <Link to="/mi-perfil/editar/datos-imex">Importador/Exportador</Link> para mejorar tu experiencia en el sitio.</Alert>
					: null }

				<Card.Text>
					{ busquedasDespachanteCount === 0 && busquedasAgenteCount === 0
						? "Por el momento no hay ninguna búsqueda que coincida con tu perfil. Te notificaremos cuando haya alguna."
						: <>Las siguientes búsquedas coinciden con tu perfil.<br />Respondé rápido para mejorar tus chances de contratación!</>
					}
				</Card.Text>

				<p>
					<Button as={Link} to="/busquedas/despachante-de-aduana" icon={<FaProjectDiagram />} disabled={busquedasDespachanteCount == 0}>
						{busquedasDespachanteCount == 1 ? "1 búsqueda" : busquedasDespachanteCount + " búsquedas"} de despachante
					</Button>
				</p>
				<p>
					<Button as={Link} to="/busquedas/agente-de-carga" icon={<FaShip />} disabled={busquedasAgenteCount == 0}>
						{busquedasAgenteCount == 1 ? "1 búsqueda" : busquedasAgenteCount + " búsquedas"} de agente
					</Button>
				</p>
			</Card.Body>
		</Card>
	);
}

export default DashboardBusquedasMisMatches;
