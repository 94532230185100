import React, { useState } from "react";
import { FaLock, FaUnlock } from "react-icons/fa";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "../../Components/Button";

import LoginForm from "../../Components/LoginForm";
import { postForm } from "../../utils.js";


function PageUsersRestablecerContrasena({ hash }) {
	const [changedPassword, setChangedPassword] = useState(false);

	const [message, setMessage] = useState(null);
	const [loading, setLoading] = useState(false);

	const onFormSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();

		setLoading(true);
		setMessage(null);
		postForm(event.currentTarget)
			.then(response => {
				setLoading(false);
				if (response.success) {
					setChangedPassword(true);
				} else {
					setMessage(response.message);
				}
			})
			.catch(err => {
				setLoading(false);
				console.error(err);
			});
	};

	const changePasswordForm = (
		<>
			<h1>Cambia tu contraseña</h1>
			<p>Ingresa tu nueva contraseña y su confirmación:</p>
			<Form action="/api/usuarios/restablecer-contrasena" method="post" onSubmit={onFormSubmit}>
				<Form.Control name="hash" type="hidden" value={hash} />
				<InputGroup className="mb-2">
					<InputGroup.Prepend>
						<InputGroup.Text><FaLock /></InputGroup.Text>
					</InputGroup.Prepend>
					<Form.Control name="password" type="password" placeholder="Contraseña" required />
				</InputGroup>

				<InputGroup className="mb-2">
					<InputGroup.Prepend>
						<InputGroup.Text><FaLock /></InputGroup.Text>
					</InputGroup.Prepend>
					<Form.Control name="confirm_password" type="password" placeholder="Confirmar contraseña" required />
				</InputGroup>

				{message ? (<Alert variant="danger">{message}</Alert>) : null}

				<div style={{textAlign: "right"}}>
					<p>
						<Button icon={<FaUnlock />} loading={loading} variant="primary" type="submit">Continuar</Button>
					</p>
				</div>
			</Form>
		</>
	);

	const successForm = (
		<>
			<h1>Listo! Ya puedes ingresar con tu nueva contraseña:</h1>
			<LoginForm />
		</>
	);

	return (
		<div className="PaddedPage">
			<Container className="NarrowPage">
				{ changedPassword ? successForm : changePasswordForm }
			</Container>
		</div>
	);
}

export default PageUsersRestablecerContrasena;
