import React from "react";
import { navigate } from "@reach/router";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "../../Components/Button";
import {FaBan, FaSave, FaSearch, FaTrash} from "react-icons/fa";
import useReferenceTables from "../../data/referenceTables";
import { defaultFormSubmitHandler } from "../../utils.js";
import { toast } from "react-toastify"
import { Loading } from "../ccQuery";
import Modal from "react-bootstrap/esm/Modal";


function FormBusquedaDespachante({ busqueda }) {
	const [ loading, setLoading ] = React.useState(false);
	const [ message, setMessage ] = React.useState(null);
	const [ alertVariant, setAlertVariant ] = React.useState(null);
	const [ showConfirm, setShowConfirm ] = React.useState(false);
	const [ publicarBusquedaCallback, setPublicarBusquedaCallback ] = React.useState(() => {});

	const referenceTables = useReferenceTables();
	const [ formState, updateFormState ] = React.useState(busqueda || {
		titulo: "",
		via: "",
		mercaderia: "",
		condicion_venta: "",
		contratar_seguro: "",
		valor: "",
		divisa: "",
		lugar_origen: "",
		lugar_destino: "",
		tipo_de_bulto: "",
		cantidad_de_bultos: "",
		peso_total: "",
		volumen_total: "",
		dimensiones_largo: "",
		dimensiones_ancho: "",
		dimensiones_alto: "",
		es_apilable: false,
		es_rotable: false,
		es_imo: false,
		imo_un: "",
		imo_clase: "",
		comentarios: "",
	});

	const onChange = event => {
		updateFormState({
			...formState,
			[event.target.name]: event.target.type == "checkbox" ? event.target.checked : event.target.value,
		});
	};

	const onSelectChange = (values, options) => {
		updateFormState({
			...formState,
			[options.name]: typeof values === "object" && values ? values.id : values,
		});
	};

	const submitHandler = defaultFormSubmitHandler({
		setLoading: setLoading,
		setResultMessage: setMessage,
		setResultVariant: setAlertVariant,
		onSuccess: res => {
			toast.info("Búsqueda grabada.");
			navigate(res.data.url);
		},
	});
	const onSubmit = e => {
		const form = e.currentTarget;
		e.preventDefault();
		if (busqueda && busqueda._id) {
			submitHandler(e);
		} else {
			setPublicarBusquedaCallback(() => {
				return () => {
					e.currentTarget = form; // Necesitamos settearlo porque después de bubbling puede estar setteado en null
					setShowConfirm(false);
					submitHandler(e);
				};
			});
			setShowConfirm(true);
		}
	};

	if (Object.keys(referenceTables).length === 0) {
		return <Loading	/>;
	}

	return (
		<>
			<Form method={busqueda && busqueda._id ? "put" : "post"} action="/api/busquedas/agente" onChange={onChange} onSubmit={onSubmit}>
				<Form.Control type="hidden" name="id" value={busqueda ? busqueda._id : ""} />

				<Form.Group as={Row}>
					<Form.Label column lg="3" className="text-lg-right">Título de la operación (privado)</Form.Label>
					<Col lg="9">
						<Form.Control
							name="titulo"
							type="text"
							defaultValue={formState.titulo}
							placeholder="ej. Proveedor X"
							maxLength="255"
							required
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column lg="3" className="text-lg-right">Transporte vía</Form.Label>
					<Col lg="9">
						<Select
							name="via"
							delimiter=","
							options={referenceTables.agente_via}
							value={referenceTables.agente_via.filter(v => v.id == formState.via)}
							onChange={onSelectChange}
							placeholder="opcional"
							className="mw-10e"
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column lg="3" className="text-lg-right">Descripción de la mercadería</Form.Label>
					<Col lg="9">
						<Form.Control
							name="mercaderia"
							type="text"
							defaultValue={formState.mercaderia}
							maxLength="1000"
							placeholder="ej. Atún enlatado"
							required
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column lg="3" className="text-lg-right">Condición de venta</Form.Label>
					<Col lg="9">
						<Select
							name="condicion_venta"
							delimiter=","
							options={referenceTables.agente_incoterms}
							value={referenceTables.agente_incoterms.filter(v => v.id == formState.condicion_venta)}
							onChange={onSelectChange}
							placeholder=""
							className="mw-30e"
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column lg="3" className="text-lg-right">Lugar de origen</Form.Label>
					<Col lg="9">
						<Form.Control
							name="lugar_origen"
							type="text"
							as="textarea"
							defaultValue={formState.lugar_origen}
							maxLength="1000"
							rows="3"
							cols="30"
						/>
						<div className="text-muted">
							* De no ser el puerto/aeropuerto, ingresar dirección completa con código postal incluído.
						</div>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column lg="3" className="text-lg-right">Lugar de destino</Form.Label>
					<Col lg="9">
						<Form.Control
							name="lugar_destino"
							type="text"
							as="textarea"
							defaultValue={formState.lugar_destino}
							maxLength="1000"
							rows="3"
							cols="30"
						/>
						<div className="text-muted">
							* De no ser el puerto/aeropuerto, ingresar dirección completa con código postal incluído.
						</div>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column lg="3" className="text-lg-right">Bultos</Form.Label>
					<Col lg="9">
						<Row>
							<Col md="4" className="d-flex">
								<Form.Label column lg="4" className="text-lg-right">Cantidad</Form.Label>
								<Col lg="8">
									<Form.Control
										name="cantidad_de_bultos"
										type="number"
										defaultValue={formState.cantidad_de_bultos}
										required
										className="w-10e"
									/>
								</Col>
							</Col>

							<Col md="8" className="d-flex b-info">
								<Form.Label column lg="3" className="text-lg-right">Tipo</Form.Label>
								<Col lg="9">
									<Form.Control
										name="tipo_de_bulto"
										type="text"
										defaultValue={formState.tipo_de_bulto}
										required
										maxLength="255"
										placeholder="ej. paletas, cajones, cajas"
									/>
								</Col>
							</Col>
						</Row>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column lg="3" className="text-lg-right">Peso total</Form.Label>
					<Col lg="9">
						<Form.Control
							name="peso_total"
							type="text"
							defaultValue={formState.peso_total}
							maxLength="255"
							placeholder="ej. 25kg, 3 tn"
							required
							className="mw-20e"
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column lg="3" className="text-lg-right">Volumen total</Form.Label>
					<Col lg="9">
						<Form.Control
							name="volumen_total"
							type="text"
							defaultValue={formState.volumen_total}
							maxLength="255"
							placeholder="ej. 2 m3"
							required
							className="mw-20e"
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column lg="3" className="text-lg-right">Medidas</Form.Label>
					<Col lg="9">
						<Row>
							<Col md="4">
								<Form.Control
									name="dimensiones_largo"
									type="text"
									defaultValue={formState.dimensiones_largo}
									maxLength="255"
									placeholder="largo (ej. 3 m, 3 metros)"
									required
									className="mw-20e"
								/>
							</Col>
							<Col md="4">
								<Form.Control
									name="dimensiones_ancho"
									type="text"
									defaultValue={formState.dimensiones_ancho}
									maxLength="255"
									placeholder="ancho (ej. 3 m, 3 metros)"
									required
									className="mw-20e"
								/>
							</Col>
							<Col md="4">
								<Form.Control
									name="dimensiones_alto"
									type="text"
									defaultValue={formState.dimensiones_alto}
									maxLength="255"
									placeholder="alto (ej. 3 m, 3 metros)"
									required
									className="mw-20e"
								/>
							</Col>
						</Row>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Col lg="3" />
					<Col lg="9">
						<Form.Check
							name="contratar_seguro"
							type="checkbox"
							id="contratar_seguro"
							label="deseo contratar seguro"
							defaultChecked={formState.contratar_seguro}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row} hidden={!formState.contratar_seguro}>
					<Col lg="3" />
					<Col lg="9" className="pl-5">
						<p>Indica el valor y divisa de la mercadería</p>
						<div className="d-flex align-items-center pl-3">
							<Form.Control
								name="valor"
								type="text"
								defaultValue={formState.valor}
								maxLength="255"
								placeholder="valor (ej. 1250.95)"
								className="mw-10e flex-grow-0 mr-3"
							/>
							<Form.Control
								name="divisa"
								type="text"
								defaultValue={formState.divisa}
								maxLength="255"
								placeholder="divisa (ej. USD)"
								className="mw-10e flex-grow-0"
							/>
						</div>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Col lg="3" />
					<Col lg="9">
						<Form.Check
							name="es_apilable"
							type="checkbox"
							id="es_apilable"
							label="es apilable"
							defaultChecked={formState.es_apilable}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Col lg="3" />
					<Col lg="9">
						<Form.Check
							name="es_rotable"
							type="checkbox"
							id="es_rotable"
							label="es rotable"
							defaultChecked={formState.es_rotable}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Col lg="3" />
					<Col lg="9">
						<Form.Check
							name="es_imo"
							type="checkbox"
							id="es_imo"
							label="es IMO (carga peligrosa)"
							defaultChecked={formState.es_imo}
						/>
					</Col>
				</Form.Group>

				{ formState.es_imo
					? (
						<Form.Group as={Row}>
							<Col lg="3" />
							<Col lg="9" className="pl-5">
								<Form.Group as={Row}>
									<Form.Label column className="mw-5e">U.N.</Form.Label>
									<Col xs="9">
										<Form.Control
											name="imo_un"
											type="text"
											defaultValue={formState.imo_un}
											maxLength="255"
											required
										/>
									</Col>
								</Form.Group>

								<Form.Group as={Row}>
									<Form.Label column className="mw-5e">Clase</Form.Label>
									<Col xs="9">
										<Form.Control
											name="imo_clase"
											type="text"
											defaultValue={formState.imo_clase}
											maxLength="255"
											required
										/>
									</Col>
								</Form.Group>
							</Col>
						</Form.Group>
					)
					: null
				}

				<Form.Group as={Row}>
					<Form.Label column lg="3" className="text-lg-right">Comentarios adicionales (opcionales, públicos)</Form.Label>
					<Col lg="9">
						<Form.Control
							name="comentarios"
							as="textarea"
							defaultValue={formState.comentarios}
							placeholder="ej. detalles que consideres adecuados aclarar a los agentes"
							maxLength="1000"
							rows="3"
							cols="40"
						/>
					</Col>
				</Form.Group>

				<Row>
					<Col lg="3" />
					<Col lg="9">
						{ message ? (<Alert variant={alertVariant}>{message}</Alert>) : null }
						<Button type="submit" icon={busqueda ? <FaSave /> : <FaSearch />} loading={loading}>{ busqueda ? "Grabar cambios" : "Pedir cotización..."}</Button>
					</Col>
				</Row>
			</Form>

			<Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Confirma la publicación</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Al publicar esta búsqueda notificaremos a los agentes de carga registrados para que te coticen esta operación.
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={() => setShowConfirm(false)} icon={<FaBan />}>Cancelar</Button>
					<Button variant="danger" onClick={publicarBusquedaCallback} loading={loading} icon={<FaSearch />}>Publicar búsqueda</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default FormBusquedaDespachante;
