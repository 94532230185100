import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import Select from "react-select";
import { Loading } from "../ccQuery";
import Button from "../Button";
import TablaGastos from "../TablaGastos";
import { FaPlus, FaSave, FaTimes, FaTrash } from "react-icons/fa";
import { defaultFormSubmitHandler } from "../../utils";
import { toast } from "react-toastify";

function FormCotizacionAgente({ busqueda, cotizacion, onSave, onCancel }) {
	onSave = onSave ? onSave : () => {};
	onCancel = onCancel ? onCancel : () => {};
	cotizacion = cotizacion || {};

	const [ loading, setLoading ] = React.useState(false);
	const [ message, setMessage ] = React.useState(null);
	const [ alertVariant, setAlertVariant ] = React.useState(null);
	// const [ showConfirm, setShowConfirm ] = React.useState(false);
	// const [ publicarBusquedaCallback, setPublicarBusquedaCallback ] = React.useState(() => {});
	const [ formState, setFormState ] = React.useState(cotizacion);

	const onTablaGastosChange = React.useCallback(name => rows => {
		const out = {
			...formState,
			[name]: rows,
		};
		setFormState(out);
	}, [formState]);

	const onSubmit = defaultFormSubmitHandler({
		setLoading: setLoading,
		setResultMessage: setMessage,
		setResultVariant: setAlertVariant,
		onSuccess: r => {
			toast.info("Cotización enviada exitosamente.");
			onSave();
		},
		onError: e => {
			console.error(e);
		},
	});


	return (
		<Card>
			<Card.Header>
				<Card.Title>{ cotizacion._id ? "Editar cotización" : "Envía tu cotización" }</Card.Title>
			</Card.Header>
			<Card.Body>
				<Form method="post" action={"/api/busquedas/agente/cotizar/" + busqueda._id} onSubmit={onSubmit}>
					<Form.Control type="hidden" name="id" value={cotizacion ? cotizacion._id : null} />

					<Form.Group as={Row}>
						<Form.Label column lg="4" className="text-lg-right">Frecuencia del servicio</Form.Label>
						<Col lg="8">
							<Form.Control
								name="frecuencia_servicio"
								type="text"
								defaultValue={formState.frecuencia_servicio}
								placeholder="ej. semanal, quincenal, etc."
								maxLength="255"
								required
								className="mw-30e"
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row}>
						<Form.Label column lg="4" className="text-lg-right">Tiempo de tránsito</Form.Label>
						<Col lg="8">
							<Form.Control
								name="tiempo_transito"
								type="text"
								defaultValue={formState.tiempo_transito}
								placeholder="ej. 30 días"
								maxLength="255"
								required
								className="mw-10e"
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row}>
						<Form.Label column lg="4" className="text-lg-right">Tarifa flete</Form.Label>
						<Col lg="8">
							<Form.Control
								name="tarifa_flete"
								type="text"
								defaultValue={formState.tarifa_flete}
								placeholder="ej. 300 USD + IVA"
								maxLength="255"
								required
								className="mw-20e"
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row}>
						<Form.Label column lg="4" className="text-lg-right">Tarifa seguro</Form.Label>
						<Col lg="8">
							<Form.Control
								name="tarifa_seguro"
								type="text"
								defaultValue={formState.tarifa_seguro}
								placeholder="ej. 300 USD + IVA"
								maxLength="255"
								required
								className="mw-20e"
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row}>
						<Form.Label column lg="4" className="text-lg-right">Gastos en origen</Form.Label>
						<Col lg="8">
							<TablaGastos namePrefix="gastos_en_origen" value={formState.gastos_en_origen} onChange={onTablaGastosChange("gastos_en_origen")} />
						</Col>
					</Form.Group>

					<Form.Group as={Row}>
						<Form.Label column lg="4" className="text-lg-right">Gastos en destino</Form.Label>
						<Col lg="8">
							<TablaGastos namePrefix="gastos_en_destino" value={formState.gastos_en_destino} onChange={onTablaGastosChange("gastos_en_destino")} />
						</Col>
					</Form.Group>

					<Form.Group as={Row}>
						<Form.Label column lg="4" className="text-lg-right">Gastos eventuales</Form.Label>
						<Col lg="8">
							<TablaGastos namePrefix="gastos_eventuales" value={formState.gastos_eventuales} onChange={onTablaGastosChange("gastos_eventuales")} />
						</Col>
					</Form.Group>

					<Form.Group as={Row}>
						<Form.Label column lg="4" className="text-lg-right">Costo total</Form.Label>
						<Col lg="8">
							<Form.Control
								name="costo_total"
								type="text"
								defaultValue={formState.costo_total}
								placeholder="ej. 1000 USD + IVA"
								maxLength="1000"
								className="mw-20e"
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row}>
						<Form.Label column lg="4" className="text-lg-right">Cotización válida por</Form.Label>
						<Col lg="8">
							<Form.Control
								name="validez_cotizacion"
								type="text"
								defaultValue={formState.validez_cotizacion}
								placeholder="ej. 15 días"
								maxLength="255"
								required
								className="mw-20e"
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row}>
						<Form.Label column lg="4" className="text-lg-right">Comentarios</Form.Label>
						<Col lg="8">
							<Form.Control
								name="comentarios"
								as="textarea"
								defaultValue={formState.comentarios}
								placeholder=""
								maxLength="1000"
								rows="3"
								cols="40"
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row}>
						<Col lg="4" />
						<Col lg="8">
							{ message ? (<Alert variant={alertVariant}>{message}</Alert>) : null }
							<Button type="submit" icon={<FaSave />} loading={loading}>Grabar</Button>
							<Button icon={<FaTimes />} variant="link" hidden={!cotizacion._id} onClick={onCancel}>Cancelar</Button>
						</Col>
					</Form.Group>
				</Form>
			</Card.Body>
		</Card>
	);
}

export default FormCotizacionAgente;
