import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "@reach/router";
import Container from "react-bootstrap/Container";

function PagePoliticaPrivacidad() {
	const CC = <strong>Conexión COMEX</strong>;

	return (
		<Container className="PaddedPage">
			<Helmet>
				<title>Política de Privacidad - Conexión COMEX</title>
				<meta name="description" content="Política de Privacidad de Conexión COMEX" />
			</Helmet>

			<h1>Política de privacidad</h1>
			<p>En {CC} nuestro compromiso es hacer el máximo esfuerzo para que tu información se encuentre a salvo. En este documento nos referiremos tanto a la información personal de cada usuario como la información de los negocios a los que representa (Despachante de Aduana, Agente de Carga, etc.) como "Información del Usuario".</p>
			<p>La naturaleza de {CC} es poner en contacto a los distintos actores del mundo de Comercio Exterior, por lo que los datos de contacto, incluyendo pero no limitado a razón social, CUIT, teléfono o email, podrán ser compartidos con otros usuarios con el fin de poner en contacto a clientes con proveedores.</p>

			<h2>Información recolectada y su uso</h2>
			<p>Además de la información provista por el Usuario en los distintos formularios en este sitio, {CC} podrá recolectar información adicional con el objetivo de analizar el flujo de datos y comportamientos en el sitio (usualmente conocido como "Analytics").</p>
			<p>{CC} brinda servicios para conectar profesionales de Comercio Exterior, es por ello que en diversas oportunidades la Información del Usuario se compartirá automáticamente entre clientes y proveedores que hayan mostrado interés en trabajar juntos.</p>
			<p>Cuando un Usuario realiza una búsqueda de despachante de aduana o agente de carga, los datos de la búsqueda y sus datos de perfil serán automáticamente compartidos con los posibles proveedores del servicio, incluyendo pero no limitado a la razón social, CUIT, frecuencia de operaciones, y datos de contacto como email y teléfono.</p>

			<h2>Cómo se comparte la Información Personal</h2>
			<p>{CC} no vende ni comercializa información que identifique a sus Usuarios.</p>
			<p>Ocasionalmente es necesario exportar la Información de Usuario a servicios de terceros para poder brindar servicios normalmente, incluyendo pero no limitado a servicios de envío de emails o cualquier otro proveedor de servicios fundamentales para el correcto funcionamiento del sitio.</p>
			<p>{CC} no es responsable del uso que cualquier usuario u otra persona pudieran hacer de la información publicada en este sitio. Los usuarios aceptan que {CC} no será responsable de las pérdidas ni daños que se puedan generar como resultado de las conversaciones o negociaciones entre usuarios. En consecuencia, liberas a {CC} de todo tipo de responsabilidad en caso de que tengas un conflicto con uno o más usuarios.</p>


			<h2>Publicidad, Cookies y Otras Tecnologías</h2>
			<p>El usuario reconoce y aceptas expresamente que {CC} podrá utilizar un sistema de seguimiento de conducta mediante la utilización de "cookies", "web beacons" y/u otras tecnologías similares.</p>
			<p>Estas tecnologías se utilizan con el fin de conocer los intereses y el comportamiento de quienes visitan o son usuarios de nuestro sitio web y, de esa forma, darles un mejor servicio o proveerles información relacionada.</p>

			<hr />
			<p>Esta declaración de Privacidad es una parte fundamental de los <Link to="/terminos-y-condiciones">Términos y Condiciones</Link> de {CC} y su aceptación es requerimiento ineludible para el uso de los servicios prestados por {CC}.</p>
		</Container>
	);
}

export default PagePoliticaPrivacidad;
