import React, { useState, useRef } from "react";
import { FaAt, FaLock, FaSignInAlt } from "react-icons/fa";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "../../Components/Button";
import Alert from "react-bootstrap/Alert";
import Recaptcha from "react-google-recaptcha";
import { defaultFormSubmitHandler } from "../../utils.js";

function PageUsersRegistrio() {
	const recaptchaRef = useRef(null);
	const [message, setMessage] = useState(null);
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [hideRecaptcha, setHideRecaptcha] = useState(false);
	const [alertVariant, setAlertVariant] = useState(null);
	const [loading, setLoading] = useState(false);

	const onSubmit = defaultFormSubmitHandler({
		setLoading: setLoading,
		setResultMessage: setMessage,
		setResultVariant: setAlertVariant,
		setButtonDisabled: setButtonDisabled,
		onSuccess: () => {
			setHideRecaptcha(true);
		},
		onError: () => {
			recaptchaRef.current.reset();
		},
	});

	return (
		<div className="PaddedPage">
			<Container className="NarrowPage">
				<h1>Crear cuenta</h1>
				<p>Ingresa tus datos y te llegará un email para confirmar tu cuenta:</p>
				<Form action="/api/usuarios/registro" method="post" onSubmit={onSubmit}>
					<InputGroup className="mb-2">
						<InputGroup.Prepend>
							<InputGroup.Text><FaAt /></InputGroup.Text>
						</InputGroup.Prepend>
						<Form.Control
							name="email"
							type="email"
							placeholder="Email"
							autoComplete="email"
							required
							maxLength="255"
						/>
					</InputGroup>

					<InputGroup className="mb-2">
						<InputGroup.Prepend>
							<InputGroup.Text><FaLock /></InputGroup.Text>
						</InputGroup.Prepend>
						<Form.Control
							name="password"
							type="password"
							placeholder="Contraseña"
							autoComplete="new-password"
							required
							maxLength="255"
						/>
					</InputGroup>

					{ message ? (<Alert variant={alertVariant}>{message}</Alert>) : null }

					<div style={{display: "flex", flexFlow: "column nowrap", alignItems: "flex-end"}}>
						{ hideRecaptcha ? null : <Recaptcha ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} hl="es" /> }
						<br />
						<p>
							<Button
								icon={<FaSignInAlt />}
								variant="primary"
								type="submit"
								loading={loading}
								disabled={buttonDisabled}
							>
								Continuar
							</Button>
						</p>
					</div>
				</Form>
			</Container>
		</div>
	);
}

export default PageUsersRegistrio;
