import React, { useState } from "react";
import { Link } from "@reach/router";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { FaBars, FaEnvelope, FaSignInAlt, FaUser, FaSearch } from "react-icons/fa";
import { useUserContext } from "../UserContext";
import NavLink from "./NavLink";

function AppHeader() {
	const userContext = useUserContext();
	const [ expanded, setExpanded ] = useState(false);

	const onNavClick = () => {
		setExpanded(false);
	};

	const onNavToggle = () => {
		setExpanded(!expanded);
	};

	let userItem = null;
	let notificationItem = null;
	if (!userContext.loading) {
		const style = {minWidth: "150px"};
		if (userContext.user) {
			userItem = <Nav.Item style={style}><Nav.Link as={Link} onClick={onNavClick} to="/dashboard"><FaUser />{' '}Mi Cuenta</Nav.Link></Nav.Item>;
		} else {
			userItem = <Nav.Item style={style}><Nav.Link as={Link} onClick={onNavClick} to="/login"><FaSignInAlt />{' '}Iniciar Sesión</Nav.Link></Nav.Item>;
		}
	}

	return (
		<Navbar
			id="MainNavbar"
			bg="light"
			variant="light"
			expand="md"
			sticky="toooop"
			expanded={expanded}
			onToggle={onNavToggle}
		>
			<Container fluid="md">
				<Link to="/" className="NavBarBrand">
					<span style={{display: "flex", "flexFlow": "no-wrap", "alignItems": "center"}}>
						<img src={process.env.PUBLIC_URL + "/img/logo.png"} className="App-logo" alt="logo" style={{height: "40px", marginRight: "0.5em"}} />
						&nbsp;
						<span style={{"lineHeight": "1em"}}>
							CONEXIÓN<br />
							<span style={{"lineHeight": "1em", "fontSize": "1.22em", "fontWeight": "bold", "letterSpacing": "3px"}}>COMEX</span>
						</span>
					</span>
				</Link>
				<Navbar.Toggle aria-controls="main-navbar" />
				<Navbar.Collapse id="main-navbar">
					<Nav variant="pills" className="ml-auto">
						<Nav.Item><Nav.Link as={NavLink} onClick={onNavClick} to="/como-funciona"><FaSearch />{' '}Cómo funciona</Nav.Link></Nav.Item>
						<Nav.Item><Nav.Link as={NavLink} onClick={onNavClick} to="/planes"><FaBars />{' '}Planes</Nav.Link></Nav.Item>
						<Nav.Item><Nav.Link as={NavLink} onClick={onNavClick} to="/contacto"><FaEnvelope />{' '}Contacto</Nav.Link></Nav.Item>
						{ notificationItem }
						{ userItem }
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default AppHeader;
