import React from "react";
import BusquedaDespachantePostulacion from "./BusquedaDespachantePostulacion";

import BusquedaDespachanteMiPostulacion from "../../Components/busquedas/BusquedaDespachanteMiPostulacion.js";
import { Loading, useQueryCC, useQueryClient } from "../ccQuery";
import { useUserContext } from "../../UserContext";

function BusquedaDespachantePostulaciones({ busqueda }) {
	const userContext = useUserContext();
	const queryClient = useQueryClient();

	const queryKey = ["postulaciones_despachante", busqueda._id];
	const { isLoading, data, error, isFetching } = useQueryCC(
		queryKey,
		() => fetch(`/api/busquedas/despachante-de-aduana/${busqueda._id}/postulaciones`)
	);

	if (isLoading) {
		return <Loading />;
	}

	const busquedaPropia = userContext.user._id.toString() == busqueda.user_id.toString();
	const postulaciones = data.postulaciones || [];

	const refreshPostulaciones = () => {
		queryClient.invalidateQueries(queryKey);
	};

	return (
		<span>
			{
				busquedaPropia
				? <PostulacionesOfrecidas busqueda={busqueda} postulaciones={postulaciones} />
				: 	userContext.user.is_despachante
					? <BusquedaDespachanteMiPostulacion busqueda={busqueda} postulaciones={postulaciones} onPostulacion={refreshPostulaciones} onCancelPostulacion={refreshPostulaciones} />
					: null
			}
		</span>

	);
}


function PostulacionesOfrecidas({ busqueda, postulaciones }) {
	let title;
	let content;

	if (postulaciones.length === 0) {
		title = "No hay coincidencias por ahora";
		content = <p>Por el momento no hay ningún despachante que coincida con tu búsqueda.<br />Te notificaremos en cuanto un despachante se postule para tus operaciones.</p>;
	} else {
		postulaciones.sort((a, b) => {
			if (a.relevancia.value != b.relevancia.value) {
				return b.relevancia.value - a.relevancia.value;
			} else {
				return a.created_at <= b.created_at ? -1 : 1;
			}
		});
		title = "Encontramos " + (postulaciones.length === 1 ? "1 despachante relevante" : postulaciones.length + " despachantes relevantes");

		let despachantesNotificadosText = "";
		if (window.location.hash) {
			const parsedHash = new URLSearchParams(
				window.location.hash.substr(1)
			);
			const count = parsedHash.get("despachantes_notificados");
			if (count) {
				despachantesNotificadosText = <p>Y notificamos a <strong>otros {count > 2 ? count : ""} despachantes</strong> para que se postulen si están disponibles!</p>;
			}
		}

		content = (
			<>
				<br />
				{ despachantesNotificadosText }
				<p>Te notificaremos a medida que otros despachantes se postulen para tu búsqueda.</p>
				{ postulaciones.map(p => (
					<div key={p._id} className="mb-4">
						<BusquedaDespachantePostulacion busqueda={busqueda} postulacion={p} />
					</div>
				) ) }
			</>
		);
	}

	return (
		<div>
			<h2>{ title }</h2>
			{ content }
		</div>
	);
}

export default BusquedaDespachantePostulaciones;
