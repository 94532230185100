import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FaUser, FaAt, FaPaperPlane, FaGripLines, FaAlignLeft } from "react-icons/fa";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Recaptcha from "react-google-recaptcha";
import Button from "../Components/Button.js";
import { useUserContext } from "../UserContext";
import { postForm } from "../utils";

function PageContacto() {
	const userContext = useUserContext();
	const [ formState, setFormState ] = useState({});
	const [ loading, setLoading ] = useState(false);
	const [ hideCaptcha, setHideCaptcha ] = useState(false);

	useEffect(() => {
		if (userContext.user) {
			setFormState(formState => {
				return {
					...formState,
					name: userContext.user.first_name + " " + userContext.user.last_name,
					email: userContext.user.email,
				};
			});
		}
	}, [userContext]);

	const [message, setMessage] = useState(null);
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [alertVariant, setAlertVariant] = useState(null);

	const onFormSubmit = (event) => {
		event.preventDefault();
		setMessage(null);
		setLoading(true);
		postForm(event.currentTarget)
			.then(response => {
				if (response.success) {
					setHideCaptcha(true);
					setMessage(response.message);
					setAlertVariant("success");
					setButtonDisabled(true);
				} else if (response.message) {
					setMessage(response.message);
					setAlertVariant("danger");
				}
			})
			.catch(err => {
				console.error(err);
				setMessage(err.type);
				setAlertVariant("danger");
				setButtonDisabled(false);
			})
			.then(() => {
				setLoading(false);
			});
	};

	const handleFormChange = e => {
		setFormState({...formState, [e.target.name]: e.target.value});
	}

	return (
		<Container className="PaddedPage">
			<Helmet>
				<title>Conexión COMEX - Contacto</title>
				<meta name="description" content="Contactate con Conexión COMEX" />
			</Helmet>

			<Row className="justify-content-center">
				<div>
					<h1>Contactanos</h1>

					<Form action="/api/contacto" method="post" onSubmit={onFormSubmit} style={{width: "500px", maxWidth: "80vw"}}>
						<InputGroup className="mb-2">
							<InputGroup.Prepend>
								<InputGroup.Text><FaUser /></InputGroup.Text>
							</InputGroup.Prepend>
							<Form.Control
								name="name"
								value={formState.name}
								type="text"
								placeholder="Tu nombre"
								required
								maxLength="100"
								onChange={handleFormChange}
							/>
						</InputGroup>

						<InputGroup className="mb-2">
							<InputGroup.Prepend>
								<InputGroup.Text><FaAt /></InputGroup.Text>
							</InputGroup.Prepend>
							<Form.Control
								name="email"
								value={formState.email}
								type="email"
								placeholder="Tu email"
								required
								maxLength="100"
								onChange={handleFormChange}
							/>
						</InputGroup>

						<InputGroup className="mb-2">
							<InputGroup.Prepend>
								<InputGroup.Text><FaGripLines /></InputGroup.Text>
							</InputGroup.Prepend>
							<Form.Control
								name="subject"
								value={formState.subject}
								type="text"
								placeholder="Asunto del mensaje"
								maxLength="255"
								required
								onChange={handleFormChange}
							/>
						</InputGroup>

						<InputGroup className="mb-2">
							<InputGroup.Prepend style={{alignItems: "flex-start"}}>
								<InputGroup.Text><FaAlignLeft /></InputGroup.Text>
							</InputGroup.Prepend>
							<Form.Control
								name="message"
								value={formState.message}
								as="textarea"
								rows="5"
								placeholder="Ingresa tu mensaje"
								required
								onChange={handleFormChange}
							/>
						</InputGroup>

						{ message ? (<Alert variant={alertVariant}>{message}</Alert>) : null }

						<div style={{display: "flex", flexFlow: "column nowrap", alignItems: "flex-end"}}>
							{/* TODO: sólo usar recaptcha para usuarios no loggeados */}
							{ hideCaptcha ? null : <Recaptcha sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} hl="es" /> }
							<br />
							<p>
								<Button
									icon={<FaPaperPlane />}
									variant="primary"
									type="submit"
									loading={loading}
									disabled={buttonDisabled}
								>
									Enviar
								</Button>
							</p>
						</div>
					</Form>
				</div>
			</Row>
		</Container>
	);
}

export default PageContacto;
