import React from "react";
import FormBusquedaDespachante from "../../Components/busquedas/FormBusquedaDespachante";
import Page from "../../Components/Page";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "@reach/router";
import { useQueryCC } from "../../Components/ccQuery";


function PageEditarBusquedaDespachante({ id }) {
	const queryKey = ["busquedaDespachante", id];
	const { isLoading, data, error, isFetching } = useQueryCC(queryKey, () => fetch(`/api/busquedas/despachante/ver/${id}`));
	const busqueda = data ? data.busqueda : null;

	return (
		<Page id="PageBuscarDespachante">
			<Page.Title showBackButton>
				<Breadcrumb>
					<Breadcrumb.Item linkAs="span"><Link to="/dashboard">Mi Cuenta</Link></Breadcrumb.Item>
					<Breadcrumb.Item active>Editar Búsqueda de Despachante</Breadcrumb.Item>
				</Breadcrumb>
			</Page.Title>
			<Page.Content>
				{ isLoading ? "Cargando..." : <FormBusquedaDespachante busqueda={busqueda} /> }
			</Page.Content>
		</Page>
	);
}

export default PageEditarBusquedaDespachante;
