import React from "react";
import { FaKey, FaSignOutAlt, FaIdCard, FaProjectDiagram, FaShip, FaEnvelope, FaLock, FaInfoCircle, FaSearch, FaCog, FaGlobe } from "react-icons/fa";
import Alert from "react-bootstrap/Alert";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import DashboardBusquedasActivas from "../../Components/Dashboard/BusquedasActivas";
import DashboardBusquedasMisMatches from "../../Components/Dashboard/BusquedasMisMatches";
import Card from "react-bootstrap/Card";
import Button from "../../Components/Button";
import Page from "../../Components/Page";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageUsuariosCompletarRegistro from "./CompletarRegistro";
import { Link } from "@reach/router";
import { useUserContext } from "../../UserContext";

import "./Dashboard.css";

function PageUsersDashboard() {
	const userContext = useUserContext();
	const [ loggingOut, setLoggingOut ] = React.useState(false);

	let dashboard;

	const registro_completo = new URLSearchParams(window.location.search).has("registro_completo");
	const registro_completo_widget = registro_completo ? <Alert variant="info"><FaInfoCircle />Listo, tu cuenta se encuentra habilitada!</Alert> : null;

	const is_despachante_or_agente = userContext.user.is_despachante || userContext.user.is_agente;

	if (userContext.user) {
		if (userContext.user.finished_registration) {
			dashboard = (
				<Page id="PageUserDashboard">
					<Page.Title>
						<Breadcrumb>
							<Breadcrumb.Item active>Mi Cuenta</Breadcrumb.Item>
						</Breadcrumb>
					</Page.Title>
					<Page.Content>
						<Row>
							<Col lg={9}>
								{ registro_completo_widget }

								{ is_despachante_or_agente ? <DashboardBusquedasMisMatches /> : null }
								<DashboardBusquedasActivas />
							</Col>

							<Col lg={3}>
								<Card>
									<Card.Header as="h5"><FaCog	/>Configuración</Card.Header>
									<Card.Body>
										<div style={{lineHeight: "2.5em"}}>
											{userContext.user.is_admin ? <div><Link to="/admin/usuarios" style={{fontWeight: "bold"}}><FaKey />ADMIN</Link></div> : null}
											<div><Link to="/mi-perfil/editar/datos-basicos"><FaIdCard />Datos básicos</Link></div>
											<div><Link to="/mi-perfil/editar/datos-despachante" hidden={!userContext.user.is_despachante}><FaProjectDiagram />Despachante de aduana</Link></div>
											{/* <div><Link to="/mi-perfil/editar/datos-agente" hidden={!userContext.user.is_agente}><FaShip />Agente de carga</Link></div> */}
											<div><Link to="/mi-perfil/editar/datos-imex" hidden={!userContext.user.is_imex}><FaGlobe />Importador/Exportador</Link></div>
											<div><Link to="/mi-perfil/cambiar-email"><FaEnvelope />Cambiar email</Link></div>
											<div><Link to="/mi-perfil/cambiar-contrasena"><FaLock />Cambiar contraseña</Link></div>
										</div>
										<p><Button href="/auth/logout" icon={<FaSignOutAlt />} variant="primary" loading={loggingOut} onClick={() => setLoggingOut(true)}>Cerrar sesión</Button></p>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Page.Content>
				</Page>
			);
		} else {
			dashboard = (
				<PageUsuariosCompletarRegistro />
			);
		}
	}

	return dashboard;
}

export default PageUsersDashboard;
