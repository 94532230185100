import React, { useState, useEffect, useContext }  from "react";

const UserContext = React.createContext({
	user: null,
	loading: false,
	updateUser: () => new Promise(),
});

function Provider(props) {
	const [state, setState] = useState({
		user: null,
		loading: false,
		updateUser: () => {
			return fetch("/api/usuarios/yo")
				.then(res => res.json())
				.then(res => {
					if (res && res.is_admin) {
						if (window.gtag) {
							// Avoid sending pageview for admins
							window.gtag('set', {"send_page_view": false});
						}
					}
					setState({...state, loading: false, user: res});
				})
				.catch(err => {
					console.error(err);
				});
		},
	})

	useEffect(() => {
		state.updateUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { children } = props;
	return (
		<UserContext.Provider value={state}>{children}</UserContext.Provider>
	)
}

export const UserContextProvider = Provider;
export const useUserContext = () => {
	return useContext(UserContext);
};
export default UserContext;
