/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Link } from "@reach/router";

function NavLink(props) {
	return (
		<Link
			{...props}
			getProps={({ isCurrent }) => {
				return {className: "nav-link" + (isCurrent ? " navlink-current" : "")};
			}}
		/>
	);
}

export default NavLink;
