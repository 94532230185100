import React from "react";
import { navigate } from "@reach/router";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "../../Components/Button";
import {FaSave, FaSearch, FaInfoCircle} from "react-icons/fa";
import useReferenceTables from "../../data/referenceTables";
import { defaultFormSubmitHandler } from "../../utils.js";
import { useUserContext } from "../../UserContext";
import { toast } from "react-toastify"


function FormBusquedaDespachante({ busqueda }) {
	const [ loading, setLoading ] = React.useState(false);
	const [ message, setMessage ] = React.useState(null);
	const [ alertVariant, setAlertVariant ] = React.useState(null);

	const referenceTables = useReferenceTables();
	const userContext = useUserContext();
	const [ formState, updateFormState ] = React.useState({});

	React.useEffect(() => {
		if (userContext.user && Object.keys(referenceTables).length) {
			let aduanas;
			let rubros;
			let tipo_operaciones;
			let titulo;
			let comentarios;

			if (busqueda) {
				({ aduanas, rubros, titulo, comentarios, tipo_operaciones } = busqueda);
			} else if (userContext.user.is_imex) {
				aduanas = userContext.user.datos_imex.aduanas;
				rubros = userContext.user.datos_imex.rubros;
				tipo_operaciones = userContext.user.datos_imex.tipo_operaciones;
			}

			updateFormState(formState => {
				return {
					...formState,
					titulo: titulo,
					comentarios: comentarios,
					aduanas: aduanas ? referenceTables.aduanas.filter(a => aduanas.includes(a.id)) : [],
					rubros: rubros ? referenceTables.rubros.filter(a => rubros.includes(a.id)) : [],
					tipo_operaciones: tipo_operaciones ? referenceTables.tipo_operaciones.filter(a => tipo_operaciones.includes(a.id)) : [],
				};
			});
		}
	}, [userContext, referenceTables, busqueda]);

	const onChange = event => {
		updateFormState({
			...formState,
			[event.target.name]: event.target.value,
		});
	};

	const onSelectChange = (values, options) => {
		updateFormState({
			...formState,
			[options.name]: typeof values === "object" && values !== null ? values.value : values,
		});
	};

	const onSubmit = defaultFormSubmitHandler({
		setLoading: setLoading,
		setResultMessage: setMessage,
		setResultVariant: setAlertVariant,
		onSuccess: res => {
			toast.info("Búsqueda grabada.");
			navigate(res.data.url);
		},
	});

	return (
		<Form method="post" action="/api/busquedas/despachante" onChange={onChange} onSubmit={onSubmit}>
			<Form.Control type="hidden" name="id" value={busqueda ? busqueda._id : null} />

			<Form.Group as={Row}>
				<Form.Label column lg="3" className="text-lg-right">Título de esta búsqueda (privado)</Form.Label>
				<Col lg="9">
					<Form.Control
						name="titulo"
						type="text"
						defaultValue={formState.titulo}
						placeholder="ej. Proveedor X"
						maxLength="255"
						required
					/>
				</Col>
			</Form.Group>

			<Form.Group as={Row}>
				<Form.Label column lg="3" className="text-lg-right">Qué operaciones necesitas realizar?</Form.Label>
				<Col lg="9">
					<Select
						name="tipo_operaciones"
						delimiter=","
						options={referenceTables.tipo_operaciones}
						value={formState.tipo_operaciones}
						onChange={onSelectChange}
						isMulti
						isSearchable
						placeholder="Elegí al menos una opción"
						closeMenuOnSelect={false}
						required
					/>
				</Col>
			</Form.Group>

			<Form.Group as={Row}>
				<Form.Label column lg="3" className="text-lg-right">Qué rubros vas a operar?</Form.Label>
				<Col lg="9">
					<Select
						name="rubros"
						delimiter=","
						options={referenceTables.rubros}
						value={formState.rubros}
						onChange={onSelectChange}
						isMulti
						isSearchable
						placeholder="Elegí al menos una opción"
						closeMenuOnSelect={false}
						required
					/>
				</Col>
			</Form.Group>

			<Form.Group as={Row}>
				<Form.Label column lg="3" className="text-lg-right">En qué aduanas vas a operar?</Form.Label>
				<Col lg="9">
					<Select
						name="aduanas"
						delimiter=","
						options={referenceTables.aduanas}
						value={formState.aduanas}
						onChange={onSelectChange}
						isMulti
						isSearchable
						placeholder="Elegí al menos una opción"
						closeMenuOnSelect={false}
						required
					/>
				</Col>
			</Form.Group>

			<Form.Group as={Row}>
				<Form.Label column lg="3" className="text-lg-right">Comentarios adicionales (opcionales, públicos)</Form.Label>
				<Col lg="9">
					<Form.Control
						name="comentarios"
						as="textarea"
						defaultValue={formState.comentarios}
						placeholder="ej. detalles que consideres adecuados aclarar a los despachantes"
						maxLength="1000"
						rows="3"
						cols="40"
					/>
				</Col>
			</Form.Group>

			{ !busqueda ?
				<Form.Group as={Row}>
					<Form.Label column lg="3" />
					<Col lg="9">
						<Alert variant="info"><FaInfoCircle /> Al publicar esta búsqueda notificaremos a los despachantes más adecuados y se podrán postular para tus operaciones.</Alert>
					</Col>
				</Form.Group>
			: null}

			<Row>
				<Col lg="3" />
				<Col lg="9">
					{ message ? (<Alert variant={alertVariant}>{message}</Alert>) : null }
					<Button type="submit" icon={busqueda ? <FaSave /> : <FaSearch />} loading={loading}>{ busqueda ? "Grabar cambios" : "Publicar búsqueda"}</Button>
				</Col>
			</Row>
		</Form>
	);
}

export default FormBusquedaDespachante;
