import React from "react";
import PropTypes from "prop-types";
import PageUsersLogin from "./Pages/Users/Login.js";
import ErrorBoundary from "./Components/ErrorBoundary.js";
import { useUserContext } from "./UserContext";

function AdminRoute({ children }) {
	const userContext = useUserContext();
	if (userContext.loading) {
		return null;
	}
	if (!userContext.user) {
		return <PageUsersLogin />;
	}

	return (
		<ErrorBoundary>{userContext.user.is_admin ? children : null}</ErrorBoundary>
	);
}

AdminRoute.propTypes = {
	children: PropTypes.node.isRequired,
}

export default AdminRoute;
