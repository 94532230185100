import React from "react";
import FormBusquedaAgente from "../../Components/busquedas/FormBusquedaAgente";
import Page from "../../Components/Page";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "@reach/router";
import { useQueryCC } from "../../Components/ccQuery";


function EditarBusquedaAgente({ id }) {
	const queryKey = ["busquedaAgente", id];
	const { isLoading, data, error, isFetching } = useQueryCC(queryKey, () => fetch(`/api/busquedas/agente/ver/${id}`));
	const busqueda = data ? data.busqueda : null;

	return (
		<Page id="PageBuscarAgente">
			<Page.Title showBackButton>
				<Breadcrumb>
					<Breadcrumb.Item linkAs="span"><Link to="/dashboard">Mi Cuenta</Link></Breadcrumb.Item>
					<Breadcrumb.Item active>Editar Búsqueda de Agente</Breadcrumb.Item>
				</Breadcrumb>
			</Page.Title>
			<Page.Content>
				{ isLoading ? "Cargando..." : <FormBusquedaAgente busqueda={busqueda} /> }
			</Page.Content>
		</Page>
	);
}

export default EditarBusquedaAgente;
