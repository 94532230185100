import React from "react";

const itemRows = rows => {
	if (rows.length === 0) {
		return null;
	}
	return rows.map((r, idx) => (
		<tr key={idx}>
			<td className="pl-3">{ r.concepto }</td>
			<td className="text-right w-10e"><strong>{ r.valor }</strong></td>
		</tr>
	))
};


function DetalleCotizacionAgente({ cotizacion }) {
	return (
		<>
			<div>Tiempo de tránsito: <strong>{ cotizacion.tiempo_transito }</strong></div>
			<div>Cotización válida por <strong>{ cotizacion.validez_cotizacion }</strong></div>
			<div>Notas: <strong>{ cotizacion.comentarios }</strong></div>
			<br />
			<div>Costos:</div>
			<table className="table table-striped table-bordered table-sm">
				<thead>
					<tr>
						<th>Concepto</th>
						<th className="text-right">Costo</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Tarifa flete</td>
						<td className="text-right w-10e"><strong>{ cotizacion.tarifa_flete }</strong></td>
					</tr>
					<tr>
						<td>Tarifa seguro</td>
						<td className="text-right w-10e"><strong>{ cotizacion.tarifa_seguro || "-" }</strong></td>
					</tr>
					<tr><td colSpan={2}><strong>Gastos en origen</strong></td></tr>
					{ itemRows(cotizacion.gastos_en_origen) }
					<tr><td colSpan={2}><strong>Gastos en destino</strong></td></tr>
					{ itemRows(cotizacion.gastos_en_destino) }
					<tr><td colSpan={2}><strong>Gastos eventuales</strong></td></tr>
					{ itemRows(cotizacion.gastos_eventuales) }
				</tbody>
				<tfoot>
					<tr>
						<td><strong>Costo total</strong></td>
						<td className="text-right w-10e"><strong>{ cotizacion.costo_total }</strong></td>
					</tr>
				</tfoot>
			</table>
		</>
	);
}

export default DetalleCotizacionAgente;
