import React from "react";
import Page from "../Components/Page";

function PageNotFound() {
	return (
		<Page id="PageNotFound">
			<Page.Title>
				<h1>Página no encontrada</h1>
			</Page.Title>
			<Page.Content />
		</Page>
	);
}

export default PageNotFound;
