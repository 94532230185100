import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "@reach/router";
import { FaAt, FaLock, FaSignInAlt } from "react-icons/fa";
import { postForm } from "../utils.js";

import Button from "./Button";
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";

function LoginForm(props) {
	const { showRegistrationLink, redirectTo } = props;
	const [ loginError, setLoginError ] = useState(null);
	const [ loading, setLoading ] = useState(false);

	const onFormSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();
		setLoginError(null);
		setLoading(true);
		postForm(event.currentTarget)
			.then(response => {
				setLoading(false);
				if (response.error) {
					setLoginError(response.error);
				} else {
					// Hard refresh, to pick up the new session with logged user information
					window.location.href = redirectTo || "/dashboard";
				}
			})
			.catch(err => {
				setLoading(false);
				console.error(err);
			});
	};

	return (
		<Form action="/auth/login" method="post" onSubmit={onFormSubmit}>
			<InputGroup className="mb-2">
				<InputGroup.Prepend>
					<InputGroup.Text><FaAt /></InputGroup.Text>
				</InputGroup.Prepend>
				<Form.Control
					name="email"
					type="email"
					placeholder="Email"
					autoComplete="email"
					required
					maxLength="255"
				/>
			</InputGroup>

			<InputGroup className="mb-2">
				<InputGroup.Prepend>
					<InputGroup.Text><FaLock /></InputGroup.Text>
				</InputGroup.Prepend>
				<Form.Control
					name="password"
					type="password"
					placeholder="Contraseña"
					autoComplete="new-password"
					required
					maxLength="255"
				/>
			</InputGroup>

			{loginError ? (<Alert variant="danger">{loginError}</Alert>) : null}

			<div style={{textAlign: "right"}}>
				<p>
					<Button icon={<FaSignInAlt />} variant="primary" type="submit" loading={loading}>Ingresar</Button>
				</p>

				{showRegistrationLink ? (<p><Link to="/usuarios/registro">Crear cuenta gratis</Link></p>) : null }

				<p><Link to="/usuarios/olvide-mi-contrasena">Olvidé mi contraseña</Link></p>
			</div>
		</Form>
	);
}

LoginForm.propTypes = {
	showRegistrationLink: PropTypes.bool,
};

LoginForm.defaultProps = {
	showRegistrationLink: false,
};

export default LoginForm;
