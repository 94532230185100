import React from "react";
import Badge from "react-bootstrap/Badge";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import FriendlyTime from "../FriendlyTime";
import { Link } from "@reach/router";
import Button from "../Button";
import { FaSearch } from "react-icons/fa";
import { useQueryCC, Loading } from "../ccQuery";

function MisBusquedasDespachante() {
	const [ tipo, setTipo ] = React.useState("ultimas");

	const queryKey = ["mis_busquedas_despachante", tipo];
	const apiUrl = "/api/mis-busquedas/despachante-de-aduana/" + tipo;
	const { isLoading, data, error, isFetching } = useQueryCC(queryKey, () => fetch(apiUrl));
	const busquedas_despachante  = data || [];

	return (
		<>
			<p className="d-lg-flex flex-lg-row align-items-center">
				<ToggleButtonGroup type="radio" name="options" defaultValue="ultimas" onChange={value => setTipo(value)}>
					<ToggleButton variant="link" value="ultimas">Últimas</ToggleButton>
					<ToggleButton variant="link" value="activas">Activas</ToggleButton>
					<ToggleButton variant="link" value="cerradas">Cerradas</ToggleButton>
					<ToggleButton variant="link" value="todas">Todas</ToggleButton>
				</ToggleButtonGroup>
			</p>

			<div style={{minHeight: "5em"}}>
				{
					isLoading
						? <Loading />
						: busquedas_despachante.length
							? busquedas_despachante.map(b => (
								<div key={b._id} className="mb-2">
									<Button variant="light" as={Link} to={b.url}>
										<span className="d-flex align-items-center flex-wrap">
											{b.titulo}
											{' '}
											<FriendlyTime date={b.created_at} asSuffix />
											{ new Date(b.end_date) < new Date() ? <Badge variant="warning" className="ml-2">cerrada</Badge> : null }
										</span>
									</Button>
								</div>
							))
							: <p>No tenés búsquedas de despachante de aduana {tipo == "activas" || tipo == "cerradas" ? tipo : "creadas"}.</p>
				}
			</div>
			<br />
			<Button as={Link} to="/busquedas/despachante-de-aduana/nueva" variant="primary"><FaSearch />{' '}Buscar despachante de aduana...</Button>
		</>
	);
}

export default MisBusquedasDespachante;
