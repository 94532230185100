import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "@reach/router";
import Container from "react-bootstrap/Container";

function PageTerminosCondiciones() {
	const CC = <strong>Conexión COMEX</strong>;

	return (
		<Container className="PaddedPage">
			<Helmet>
				<title>Términos y Condiciones - Conexión COMEX</title>
				<meta name="description" content="Términos y condiciones de Conexión COMEX" />
			</Helmet>

			<h1>Términos y Condiciones</h1>

			<p>Este documento describe los "Términos y Condiciones" aplicables al uso de los servicios ofrecidos por {CC}.</p>

			<p>El uso del sitio y/o sus servicios implica la aceptación de estos Términos y Condiciones de uso de {CC}.</p>

			<p>CUALQUIER PERSONA ("USUARIO") QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES, DE CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO Y/O LOS SERVICIOS.</p>


			<h2>1. Registración</h2>
			<p>Para utilizar los servicios de {CC} es necesario crear una "Cuenta" de Usuario, para la cual el futuro Usuario deberá completar los formularios de registro con su información personal de manera exacta, precisa y verdadera ("Datos Personales").</p>
			<p>Todo Usuario tendrá la obligación de mantener sus Datos Personales actualizados cuando fueran modificados.</p>
			<p>{CC} NO se responsabiliza por la certeza de los Datos Personales de los Usuarios.</p>
			<p>Los Usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de sus Datos Personales.</p>
			<p>Cada Cuenta de Usuario es personal, única e intransferible, y está prohibido que un mismo Usuario registre o posea más de una Cuenta. En caso que {CC} detecte distintas Cuentas que contengan datos coincidentes o relacionados, podrá cancelar, suspender o inhabilitarlas.</p>
			<p>El Usuario será responsable por todas las operaciones efectuadas en su Cuenta, pues el acceso a la misma está restringido al ingreso y uso de su Clave de Seguridad, de conocimiento exclusivo del Usuario. El Usuario se compromete a notificar a {CC} en forma inmediata y por medio idóneo y fehaciente, cualquier uso no autorizado de su Cuenta, así como el ingreso por terceros no autorizados a la misma.</p>
			<p>{CC} se reserva el derecho de rechazar cualquier solicitud de registración o de cancelar una registración previamente aceptada, sin que esté obligado a comunicar o exponer las razones de su decisión y sin que ello genere algún derecho a indemnización o resarcimiento.</p>

			<h2>2 - Modificaciones de los Términos y Condiciones</h2>
			<p>{CC} podrá modificar los Términos y Condiciones Generales en cualquier momento haciendo públicos en el Sitio los términos modificados. Todos los términos modificados entrarán en vigor a los 10 (diez) días de su publicación. Dichas modificaciones serán comunicadas por {CC} a los Usuarios que en la Configuración de su Cuenta hayan indicado que desean recibir notificaciones de los cambios en estos Términos y Condiciones. Todo usuario que no esté de acuerdo con las modificaciones efectuadas por {CC} podrá solicitar la baja de la cuenta.</p>

			<h2>3 - Privacidad de la Información</h2>
			<p>Para {CC} la privacidad de los datos personales es muy importante. Puedes encontrar más detalles de qué datos guardamos y compartimos en nuestra <Link to="/politica-de-privacidad">Política de Privacidad</Link>.</p>

			<h2>4 - Violaciones del Sistema</h2>
			<p>No está permitida ninguna acción o uso de dispositivo, software, u otro medio tendiente a interferir tanto en las actividades y operatoria de {CC} como en las cuentas o bases de datos de {CC}. Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes sobre derecho de propiedad intelectual y/o a las prohibiciones estipuladas en este contrato harán pasible a su responsable de las acciones legales pertinentes, y a las sanciones previstas por este acuerdo, así como lo hará responsable de indemnizar los daños ocasionados.</p>

			<h2>5 - Sanciones y suspensión de operaciones</h2>
			<p>Sin perjuicio de otras medidas, {CC} podrá advertir, suspender en forma temporal o definitivamente la Cuenta de un Usuario o una publicación, aplicar una sanción que impacte negativamente en la reputación de un Usuario, iniciar las acciones que estime pertinentes y/o suspender la prestación de sus Servicios si (a) se quebrantara alguna ley, o cualquiera de las estipulaciones de los Términos y Condiciones Generales y demás políticas de {CC}; (b) si incumpliera sus compromisos como Usuario; (c) si se incurriera a criterio de {CC} en conductas o actos dolosos o fraudulentos; (d) no pudiera verificarse la identidad del Usuario o cualquier información proporcionada por el mismo fuere errónea; (e) {CC} entendiera que las publicaciones u otras acciones pueden ser causa de responsabilidad para el Usuario que las publicó, para {CC} o para los demás Usuarios en general. En el caso de la suspensión de un Usuario, sea temporal o definitiva, todas las publicaciones serán removidas del sistema.</p>
			<p>Asimismo, {CC} se reserva el derecho de eliminar o modificar cualquier contenido generado por Usuarios que no estén alineados con estos Términos y Condiciones, incluyendo pero no limitado a datos falsos, agravios, y publicidades o mensajes ajenos a la temática de Comercio Exterior.</p>

			<h2>6 - Responsabilidad</h2>
			<p>{CC} pone a disposición de los Usuarios un espacio virtual que les permite ponerse en contacto para ofrecer y/o contratar y servicios. Son los Usuarios quienes utilizan {CC} para publicar sus servicios, y definir las condiciones de su oferta.</p>
			<p>En todos los casos, al ser los Usuarios quienes publicitan sus productos y servicios, serán responsables por la existencia, calidad, cantidad, integridad o legitimidad de los productos o servicios que ofrecen, y mantendrán indemnes a {CC} por las eventuales acciones o reclamaciones que pudiesen surgir con motivo de una oferta o transacción.</p>

			<h2>7 - Alcance de los servicios de {CC}</h2>
			<p>Este acuerdo no crea ningún contrato de sociedad, de mandato, de franquicia, o relación laboral entre {CC} y el Usuario. El Usuario reconoce y acepta que {CC} no es parte en ninguna operación, ni tiene control alguno sobre la calidad, seguridad o legalidad de los bienes y servicios anunciados, la veracidad o exactitud de los anuncios, la capacidad de los Usuarios para ofrecer o contratar servicios. {CC} no puede asegurar que un usuario completará una operación ni podrá verificar la identidad o datos personales ingresados por los usuarios. {CC} no garantiza la veracidad de la publicidad de terceros que aparezca en el sitio y no será responsable por la correspondencia o contratos que el usuario celebre con dichos terceros o con otros usuarios.</p>

			<h2>8 - Fallas en el sistema</h2>
			<p>{CC} no se responsabiliza por cualquier daño, perjuicio o pérdida al usuario causados por fallas en el sistema, en el servidor o en Internet. {CC} tampoco será responsable por cualquier virus que pudiera infectar el equipo del usuario como consecuencia del acceso, uso o examen de su sitio web o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o audio contenidos en el mismo. Los usuarios NO podrán imputarle responsabilidad alguna ni exigir pago por lucro cesante, en virtud de perjuicios resultantes de dificultades técnicas o fallas en los sistemas o en Internet. {CC} no garantiza el acceso y uso continuado o ininterrumpido de su sitio. El sistema puede eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet, o por cualquier otra circunstancia ajena a {CC}; en tales casos se procurará restablecerlo con la mayor celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad. {CC} no será responsable por ningún error u omisión contenidos en su sitio web.</p>

			<h2>9 - Tarifas. Facturación</h2>
			<p>Cualquier plan contratado por el Usuario, en cualquiera de sus modalidades temporales (mensual, anual, o cualquier otra) cuenta con un período de prueba gratuito para que el Usuario pueda evaluar si el servicio es de su gusto. Por esta razón, al comenzar a facturarse los servicios se entiende que el Usuario está de acuerdo en pagar por los servicios contratados y dichos pagos no serán reintegrables.</p>
			<p>{CC} se reserva el derecho de tomar las medidas judiciales y extrajudiciales que estime pertinentes para obtener el pago del monto debido.</p>
			<p>{CC} se reserva el derecho de modificar, cambiar, agregar, o eliminar las tarifas vigentes para los productos y/o servicios ofrecidos en cualquier momento.</p>
			<p>En caso de haberse facturado cargos que no hubiesen correspondido, el usuario deberá comunicarse con nuestro equipo de Atención al Cliente para resolver dicha cuestión.</p>

			<h2>10 - Propiedad intelectual - Licencia - Enlaces</h2>
			<p>{CC} se reserva todos los derechos, incluyendo los derechos de propiedad intelectual e industrial, asociados con los servicios de {CC}, sus sitios web, los contenidos de sus pantallas, programas, bases de datos, redes, códigos, desarrollo, software, arquitectura, hardware, contenidos, información, tecnología, fases de integración, funcionalidades, dominios, archivos que permiten al Usuario acceder y crear su Cuenta, herramientas de venta, marcas, patentes, derechos de autor, diseños y modelos industriales, nombres comerciales, entre otros, y declara que están protegidos por leyes nacionales e internacionales vigentes.</p>
			<p>En ningún caso se entenderá que el Usuario tendrá algún tipo de derecho sobre los mismos excepto para utilizar el servicio de {CC} conforme a lo previsto en estos Términos y Condiciones Generales. El uso indebido o contrario a la normativa vigente de los derechos de propiedad intelectual e industrial de {CC}, así como su reproducción total o parcial, queda prohibido, salvo autorización expresa y por escrito de {CC}.</p>
			<p>Los Usuarios tampoco podrán comunicar que los productos o servicios que ofrecen son patrocinados, promovidos, producidos, ofrecidos y/o vendidos por {CC} y deberán abstenerse de realizar cualquier acto que pudiera causar un daño, pérdida de reputación, o disminución del valor de los derechos de propiedad intelectual e industrial de {CC}.</p>
			<p>El sitio puede contener enlaces a sitios web de terceros. En virtud que {CC} no tiene control sobre tales sitios, no será responsable por los contenidos, materiales, acciones y/o servicios prestados por los mismos, ni por daños o pérdidas ocasionadas por la utilización de éstos, causados directa o indirectamente. La presencia de enlaces a otros sitios web no implica una sociedad, relación, aprobación, respaldo de {CC} con dichos sitios y sus contenidos.</p>

			<h2>11 - Indemnidad</h2>
			<p>El Usuario mantendrá indemne a {CC}, así como a sus filiales, empresas controladas y/o controlantes, funcionarios, directivos, sucesores, administradores, representantes y empleados, por cualquier reclamo iniciado por otros Usuarios, terceros o por cualquier Organismo, relacionado con sus actividades en el Sitio, el cumplimiento y/o el incumplimiento de los Términos y Condiciones Generales o demás Políticas, así como respecto de cualquier violación de leyes o derechos de terceros.</p>
			<p>A tal fin, el Usuario faculta a {CC} a: i) intervenir y representarlo en dichos reclamos, pudiendo celebrar los acuerdos transaccionales que considere oportunos y que tiendan a evitar mayores costos y/o evitar eventuales contingencias sin limitación, en su nombre y representación; ii) retener y debitar de su Cuenta de {CC} y/o de la(s) cuenta(s) bancaria(s) registrada(s) en la Cuenta {CC} los fondos existentes y/o futuros para recuperar cualquier gasto incurrido en relación a los supuestos del párrafo anterior, los cuales podrán incluir, entre otros, los honorarios de los letrados intervinientes y las costas judiciales en una cantidad razonable, y/o iii) generar débitos específicos en su facturación.</p>
		</Container>
  );
}

export default PageTerminosCondiciones;
