import React, { useState } from "react";
import { FaKey, FaLock, FaSave } from "react-icons/fa";
import { Link } from "@reach/router";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Page from "../../Components/Page.js";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "../../Components/Button";
import Alert from "react-bootstrap/Alert";
import { postForm } from "../../utils.js";

function PageUsuariosCambioContrasena() {
	const [message, setMessage] = useState(null);
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [alertVariant, setAlertVariant] = useState(null);
	const [loading, setLoading] = useState(false);

	const onFormSubmit = (event) => {
		event.preventDefault();
		setMessage(null);
		setLoading(true);
		postForm(event.currentTarget)
			.then(response => {
				setLoading(false);
				if (response.success) {
					setMessage(response.message);
					setAlertVariant("success");
				} else if (response.message) {
					setMessage(response.message);
					setAlertVariant("danger");
				}
			})
			.catch(err => {
				console.error(err);
				setLoading(false);
				setMessage("Ha ocurrido un error, por favor intenta nuevamente.");
				setAlertVariant("danger");
				setButtonDisabled(false);
			});
	};

	return (
		<Page narrow>
			<Page.Title showBackButton>
				<Breadcrumb>
					<Breadcrumb.Item linkAs="span"><Link to="/dashboard">Mi Cuenta</Link></Breadcrumb.Item>
					<Breadcrumb.Item active>Cambiar contraseña</Breadcrumb.Item>
				</Breadcrumb>
			</Page.Title>
			<Page.Content>
				<Form action="/api/usuarios/cambiar-contrasena" method="post" onSubmit={onFormSubmit}>
					<InputGroup className="mb-2">
						<InputGroup.Prepend>
							<InputGroup.Text><FaKey /></InputGroup.Text>
						</InputGroup.Prepend>
						<Form.Control
							name="password"
							type="password"
							placeholder="Contraseña actual"
							required
							maxLength="255"
						/>
					</InputGroup>

					<InputGroup className="mb-2">
						<InputGroup.Prepend>
							<InputGroup.Text><FaLock /></InputGroup.Text>
						</InputGroup.Prepend>
						<Form.Control
							name="new_password"
							type="password"
							placeholder="Nueva contraseña"
							required
							maxLength="255"
						/>
					</InputGroup>

					<InputGroup className="mb-2">
						<InputGroup.Prepend>
							<InputGroup.Text><FaLock /></InputGroup.Text>
						</InputGroup.Prepend>
						<Form.Control
							name="confirm_new_password"
							type="password"
							placeholder="Repetir nueva contraseña"
							required
							maxLength="255"
						/>
					</InputGroup>

					<p className="text-right"><Link to="/usuarios/olvide-mi-contrasena">Olvidé mi contraseña</Link></p>

					{ message ? (<Alert variant={alertVariant}>{message}</Alert>) : null }

					<div style={{textAlign: "right"}}>
						<p>
							<Button
								icon={<FaSave />}
								variant="primary"
								type="submit"
								disabled={buttonDisabled}
								loading={loading}
							>
								Grabar
							</Button>
						</p>
					</div>
				</Form>
			</Page.Content>
		</Page>
	);
}

export default PageUsuariosCambioContrasena;
