import React from "react";
import PropTypes from "prop-types";
import ErrorBoundary from "./ErrorBoundary";

function Empty({ children }) {
	return (<ErrorBoundary>{children}</ErrorBoundary>);
}

Empty.propTypes = {
	children: PropTypes.any.isRequired,
}

export default Empty;
