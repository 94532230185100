import React from "react";
import { Link } from "@reach/router";
import Button from "../../Components/Button";
import Relevancia from "../../Components/Relevancia";
import {FaBox, FaCity, FaProjectDiagram} from "react-icons/fa";
import FriendlyTime from "../FriendlyTime";
import Badge from "react-bootstrap/esm/Badge";
import useReferenceTables from "../../data/referenceTables";
import { Loading } from "../ccQuery";
import { useUserContext } from "../../UserContext";

function MapAndSortByRelevance({ ids, referenceTableById, userIds }) {
	let matching = ids.filter(id => userIds.includes(id));
	let nonMatching = ids.filter(id => !userIds.includes(id));

	matching = matching.map(id => <span key={id}><strong>{referenceTableById[id]}</strong></span>);
	nonMatching = nonMatching.map(id => <span key={id} className="text-secondary">{referenceTableById[id]}</span>);
	const out = [];

	const all = matching.concat(nonMatching);
	for (let i = 0; i < all.length; i++) {
		out.push(all[i]);
		if (i < all.length - 1) {
			out.push(<span className="text-secondary ml-1 mr-1">/</span>);
		}
	}
	return <span>{out}</span>;
}


function BusquedaDespachanteTeaser({ busqueda }) {
	const userContext = useUserContext();
	const referenceTables = useReferenceTables();
	if (!Object.keys(referenceTables).length) {
		return <Loading />;
	}

	const now = new Date();
	const is_closed = new Date(busqueda.end_date) < now;

	return (
		<Button
			variant="light"
			as={Link}
			to={busqueda.url}
			className="d-flex flex-wrap flex-md-nowrap align-items-start justify-content-between border-primary"
			style={{maxWidth: "700px"}}
		>
			<div className="text-left mx-1 my-1 flex-shrink-1" style={{maxWidth: "calc(min(100%, 500px)"}}>
				<div className="text-truncate" title="Tipo de operación">
					<FaProjectDiagram />
					<MapAndSortByRelevance ids={busqueda.tipo_operaciones} referenceTableById={referenceTables.tipo_operaciones_by_id} userIds={userContext.user.datos_despachante ? userContext.user.datos_despachante.tipo_operaciones : []} />
				</div>
				<div className="text-truncate" title="Rubros">
					<FaBox />
					<MapAndSortByRelevance ids={busqueda.rubros} referenceTableById={referenceTables.rubros_by_id} userIds={userContext.user.datos_despachante ? userContext.user.datos_despachante.rubros : []} />
				</div>
				<div className="text-truncate" title="Aduanas">
					<FaCity />
					<MapAndSortByRelevance ids={busqueda.aduanas} referenceTableById={referenceTables.aduanas_by_id} userIds={userContext.user.datos_despachante ? userContext.user.datos_despachante.aduanas : []} />
				</div>
			</div>

			<div className="text-right mx-1 my-1">
				<div><Badge>Creada <FriendlyTime date={busqueda.created_at} /></Badge></div>
				<div><Badge className={is_closed ? "text-danger" : ""}>{ is_closed ? "Finalizó" : "Finaliza"}{' '}<FriendlyTime date={busqueda.end_date} /></Badge></div>
				<div><Relevancia data={busqueda.relevancia} /></div>
			</div>
		</Button>
	);

}

export default BusquedaDespachanteTeaser;
