import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { FaInfo, FaSave } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "./Button";
import { useUserContext } from "../UserContext";
import { defaultFormSubmitHandler } from "../utils.js";

import "./UsuarioDatosBasicos.scss";

function UsuarioDatosBasicos({ onSave }) {
	const userContext = useUserContext();

	const [ formState, updateFormState ] = useState({
		first_name:     userContext.user ? userContext.user.first_name : null,
		last_name:      userContext.user ? userContext.user.last_name : null,
		is_despachante: userContext.user ? userContext.user.is_despachante : null,
		is_agente:      userContext.user ? userContext.user.is_agente : null,
		is_imex:        userContext.user ? userContext.user.is_imex : null,
		cuit:           userContext.user && userContext.user.datos_empresa ? userContext.user.datos_empresa.cuit : null,
		razon_social:   userContext.user && userContext.user.datos_empresa ? userContext.user.datos_empresa.razon_social : null,
		email:          userContext.user && userContext.user.datos_empresa ? userContext.user.datos_empresa.email : null,
		telefono:       userContext.user && userContext.user.datos_empresa ? userContext.user.datos_empresa.telefono : null,
	});

	const [ modifiedUser, setModifiedUser ] = useState(false);
	const [ loading, setLoading ] = useState(false);
	const [ message, setMessage ] = useState(null);
	const [ alertVariant, setAlertVariant ] = useState(null);

	const onChange = event => {
		let value;
		if (event.target.value === "true") value = true;
		else if (event.target.value === "false") value = false;
		else value = event.target.value
		updateFormState({
			...formState,
			[event.target.name]: value,
		})
	};

	const onSubmit = defaultFormSubmitHandler({
		setLoading: setLoading,
		setResultMessage: setMessage,
		setResultVariant: setAlertVariant,
		onSuccess: () => {
			userContext.updateUser().then(() => {
				setModifiedUser(true);
			});
		},
	});

	useEffect(() => {
		if (modifiedUser) {
			onSave();
			setModifiedUser(false);
		}
	}, [ onSave, userContext, modifiedUser ]);

	return (
		<Container className="NarrowPage FormUsuarioDatosBasicos">
			<Form method="post" action="/api/usuarios/grabar-datos-basicos" onChange={onChange} onSubmit={onSubmit}>
				<Card>
					<Card.Header as="h5">Datos Personales</Card.Header>
					<Card.Body>
						<Form.Group>
							<Form.Label>Nombre</Form.Label>
							<Form.Control
								name="first_name"
								type="text"
								defaultValue={formState.first_name}
								placeholder="Nombre"
								required
								maxLength="100"
							/>
						</Form.Group>

						<Form.Group>
							<Form.Label>Apellido</Form.Label>
							<Form.Control
								name="last_name"
								type="text"
								defaultValue={formState.last_name}
								placeholder="Apellido"
								required
								maxLength="100"
							/>
						</Form.Group>
					</Card.Body>
				</Card>

				<br />
				<br />

				<Card>
					<Card.Header as="h5">Datos de tu negocio</Card.Header>
					<Card.Body>
						<Form.Group>
							<Form.Label>Razón Social</Form.Label>
							<div>
								<Form.Control
									name="razon_social"
									type="text"
									defaultValue={formState.razon_social}
									required
									maxLength="255"
								/>
								<small className="text-muted">Si sos independiente/particular, poné tu nombre y apellido.</small>
							</div>
						</Form.Group>

						<p>Indicá tus actividades para ofrecerte un mejor servicio:</p>
						<Form.Check
							id="is_agente_checkbox"
							name="is_agente"
							label="Soy agente de carga"
							value="true"
							defaultChecked={formState.is_agente}
						/>
						<Form.Check
							id="is_despachante_checkbox"
							name="is_despachante"
							label="Soy despachante de aduana"
							value="true"
							defaultChecked={formState.is_despachante}
						/>
						<Form.Check
							id="is_imex_checkbox"
							name="is_imex"
							label="Soy importador/exportador"
							value="true"
							defaultChecked={formState.is_imex}
						/>

						<br />
						<br />

						<Alert variant="info"><FaInfo />Los siguientes datos se comparten automáticamente cuando haya una coincidencia en las búsquedas. Podés completar sólo los que quieras.</Alert>
						<Form.Group>
							<Form.Label>E-mail</Form.Label>
							<Form.Control
								name="email"
								type="email"
								defaultValue={formState.email}
								maxLength="255"
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>Teléfono</Form.Label>
							<Form.Control
								name="telefono"
								type="text"
								defaultValue={formState.telefono}
								maxLength="25"
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>CUIT</Form.Label>
							<Form.Control
								name="cuit"
								type="text"
								defaultValue={formState.cuit}
								placeholder="con o sin guiones"
								maxLength="13"
							/>
						</Form.Group>
					</Card.Body>
				</Card>

				<br />
				{ message ? (<Alert variant={alertVariant}>{message}</Alert>) : null }
				<div className="text-right">
					<Button type="submit" icon={<FaSave />} loading={loading}>Grabar</Button>
				</div>
			</Form>
		</Container>
	);
}

UsuarioDatosBasicos.propTypes = {
	onSave: PropTypes.func,
};

UsuarioDatosBasicos.defaultProps = {
	onSave: () => {},
};

export default UsuarioDatosBasicos;
