import React, { useState, useEffect } from "react";
import { FaInfoCircle, FaSave } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import Alert from "react-bootstrap/Alert";
import Button from "./Button";
import { useUserContext } from "../UserContext";
import useReferenceTables from "../data/referenceTables";
import { defaultFormSubmitHandler } from "../utils.js";


function SetupDespachanteDeAduana({ onSave }) {
	const referenceTables = useReferenceTables();
	const userContext = useUserContext();
	const [ formState, updateFormState ] = useState({});
	const [ loading, setLoading ] = useState(false);
	const [ message, setMessage ] = useState(null);
	const [ alertVariant, setAlertVariant ] = useState(null);

	const OPTIONS_OPERACIONES_MENSUALES = [
		{value: 5, label: "hasta 5 operaciones mensuales"},
		{value: 10, label: "hasta 10 operaciones mensuales"},
		{value: 20, label: "hasta 20 operaciones mensuales"},
		{value: 50, label: "hasta 50 operaciones mensuales"},
		{value: 100, label: "hasta 100 operaciones mensuales"},
		{value: 999999, label: "más de 100 operaciones mensuales"},
	];

	useEffect(() => {
		if (userContext.user && Object.keys(referenceTables).length) {
			updateFormState(formState => {
				return {
					...formState,
					operaciones_mensuales_max: userContext.user.datos_despachante.operaciones_mensuales_max,
					aduanas: userContext.user.datos_despachante.aduanas ? referenceTables.aduanas.filter(a => userContext.user.datos_despachante.aduanas.includes(a.id)) : [],
					rubros: userContext.user.datos_despachante.rubros ? referenceTables.rubros.filter(a => userContext.user.datos_despachante.rubros.includes(a.id)) : [],
					tipo_operaciones: userContext.user.datos_despachante.tipo_operaciones ? referenceTables.tipo_operaciones.filter(a => userContext.user.datos_despachante.tipo_operaciones.includes(a.id)) : [],
				};
			});
		}
	}, [userContext, referenceTables]);

	const onChange = event => {
		updateFormState({
			...formState,
			[event.target.name]: event.target.value,
		});
	};

	const onSelectChange = (values, options) => {
		updateFormState({
			...formState,
			[options.name]: typeof values === "object" && values !== null ? values.value : values,
		});
	};

	const onSubmit = defaultFormSubmitHandler({
		setLoading: setLoading,
		setResultMessage: setMessage,
		setResultVariant: setAlertVariant,
		onSuccess: onSave,
	});

	return (
		<Form method="post" action="/api/usuarios/grabar-datos-despachante" onChange={onChange} onSubmit={onSubmit}>
			<Alert variant="info" style={{fontSize: "1.1em"}}><FaInfoCircle /> Ingresá tus datos de <strong>Despachante de Aduana</strong> para aparecer en las búsquedas de posibles clientes.</Alert>

			<Form.Group as={Row}>
				<Form.Label column sm="3">Cuántas operaciones por mes podrías tomar?</Form.Label>
				<Col sm="9">
					<Select
						name="operaciones_mensuales_max"
						value={OPTIONS_OPERACIONES_MENSUALES.filter(o => o.value == formState.operaciones_mensuales_max)}
						options={OPTIONS_OPERACIONES_MENSUALES}
						onChange={onSelectChange}
					/>
				</Col>
			</Form.Group>

			<Form.Group as={Row}>
				<Form.Label column sm="3">Operaciones en las que te especializás</Form.Label>
				<Col sm="9">
					<Select
						name="tipo_operaciones"
						delimiter=","
						options={referenceTables.tipo_operaciones}
						value={formState.tipo_operaciones}
						onChange={onSelectChange}
						isMulti
						isSearchable
						placeholder="Elegí al menos una opción"
						closeMenuOnSelect={false}
					/>
				</Col>
			</Form.Group>

			<Form.Group as={Row}>
				<Form.Label column sm="3">Rubros de especialización</Form.Label>
				<Col sm="9">
					<Select
						name="rubros"
						delimiter=","
						options={referenceTables.rubros}
						value={formState.rubros}
						onChange={onSelectChange}
						isMulti
						isSearchable
						placeholder="Elegí al menos una opción"
						closeMenuOnSelect={false}
					/>
				</Col>
			</Form.Group>

			<Form.Group as={Row}>
				<Form.Label column sm="3">Aduanas en las que operás</Form.Label>
				<Col sm="9">
					<Select
						name="aduanas"
						delimiter=","
						options={referenceTables.aduanas}
						value={formState.aduanas}
						onChange={onSelectChange}
						isMulti
						isSearchable
						placeholder="Elegí al menos una opción"
						closeMenuOnSelect={false}
					/>
				</Col>
			</Form.Group>

			{/* <Form.Group as={Row}>
				<Form.Label column sm="3">Operaciones que no te interesan</Form.Label>
				<Col sm="9">
					<Form.Check type="checkbox" name="blacklist_operaciones" label="IM/EX EVENTUALES / POR UNA SOLA OPERACIÓN" />
					<Form.Check type="checkbox" name="blacklist_operaciones" label="EMPRENDEDORES O PARTICULARES QUE EVALÚEN EMPEZAR A IMPORTAR / EXPORTAR" />
					<Form.Check type="checkbox" name="blacklist_operaciones" label="PARTICULARES (COURRIER O CORREO CON ENVÍOS RETENIDOS)" />
					<Form.Check type="checkbox" name="blacklist_operaciones" label="MUDANZAS" />
				</Col>
			</Form.Group> */}

			<Row>
				<Col sm="3" />
				<Col sm="9">
					{ message ? (<Alert variant={alertVariant}>{message}</Alert>) : null }

					<Button type="submit" icon={<FaSave />} loading={loading}>Grabar</Button>
				</Col>
			</Row>
		</Form>
	);
}

export default SetupDespachanteDeAduana;
