import React from "react";
import { Router, useNavigate, useLocation } from "@reach/router";
import { QueryClient, QueryClientProvider } from "react-query"

import { FaCopyright } from "react-icons/fa";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { ToastContainer } from "react-toastify"
import { Helmet } from "react-helmet";

import PageHomepage from "./Pages/Homepage.js";
import PageTerminosCondiciones from "./Pages/TerminosCondiciones.js";
import PagePoliticaPrivacidad from "./Pages/PoliticaPrivacidad.js";
import PageContacto from "./Pages/Contacto.js";
import PagePlanes from "./Pages/Planes.js";
import PageUsersLogin from "./Pages/Users/Login.js";
import PageUsersRegistro from "./Pages/Users/Registro.js";
import PageUsuariosDatosBasicos from "./Pages/Users/DatosBasicos.js";
import PageUsuariosSetupDespachanteDeAduana from "./Pages/Users/SetupDespachanteDeAduana.js";
import PageUsuariosSetupAgenteDeCarga from "./Pages/Users/SetupAgenteDeCarga.js";
import PageUsuariosSetupImex from "./Pages/Users/SetupImex.js";
import PageUsuariosCambioContrasena from "./Pages/Users/CambioContrasena.js";
import PageUsuariosCambioEmail from "./Pages/Users/CambioEmail.js";
import PageUsersOlvidoContrasena from "./Pages/Users/OlvidoContrasena.js";
import PageUsersRegistroConfirmado from "./Pages/Users/RegistroConfirmado.js";
import PageUsersRegistroNoConfirmado from "./Pages/Users/RegistroNoConfirmado.js";
import PageUsersCambioEmailConfirmado from "./Pages/Users/CambioEmailConfirmado.js";
import PageUsersCambioEmailNoConfirmado from "./Pages/Users/CambioEmailNoConfirmado.js";
import PageUsersRestablecerContrasena from "./Pages/Users/RestablecerContrasena.js";
import PageUsersDashboard from "./Pages/Users/Dashboard.js";
import PageComoFunciona from "./Pages/PageComoFunciona.js";

import PageBuscarDespachante from "./Pages/Busquedas/BuscarDespachante.js";
import PageBusquedasDespachante from "./Pages/Busquedas/BusquedasDespachante.js";
import PageBusquedaDespachante from "./Pages/Busquedas/BusquedaDespachante.js";
import PageEditarBusquedaDespachante from "./Pages/Busquedas/EditarBusquedaDespachante.js";

import PageBuscarAgente from "./Pages/Busquedas/BuscarAgente.js";
import PageBusquedasAgente from "./Pages/Busquedas/BusquedasAgente.js";
import PageBusquedaAgente from "./Pages/Busquedas/BusquedaAgente.js";
import PageEditarBusquedaAgente from "./Pages/Busquedas/EditarBusquedaAgente.js";

import PageAdminUsers from "./Pages/Admin/Users.js";
import PageNotFound from "./Pages/NotFound.js";
import PrivateRoute from "./PrivateRoute";
import AdminRoute from "./AdminRoute";

import NavLink from "./Components/NavLink.js";
import Empty from "./Components/Empty.js";
import { UserContextProvider } from "./UserContext";
import AppHeader from "./Components/AppHeader.js";

import "animate.css/animate.min.css";
import "./Bootstrap.custom.scss";
import 'react-toastify/dist/ReactToastify.css';
import "./App.scss";

import ErrorBoundary from "./Components/ErrorBoundary.js";


function ScrollToTop({ children }) { // borrowed from https://github.com/reach/router/issues/242#issuecomment-644991279
	const navigate = useNavigate();
	const { href, state } = useLocation();

	const updateState = React.useCallback(() => {
		navigate(href, {
			state: { ...state, scrolled: true },
			replace: true,
		}).then(() => window?.scrollTo(0, 0));
	}, [href, state, navigate]);

	// Mark the page as scrolled on first mount
	// eslint-disable-next-line react-hooks/exhaustive-deps
	React.useEffect(updateState, []);

	React.useEffect(() => {
		if (!state || !state.scrolled) {
			updateState();
		}
	}, [state, updateState]);

	return children;
}

// Setup momentjs globally
import moment from "moment";
moment.locale(
	"es",
	{
		months: "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split("_"),
		monthsShort: "Enero_Feb_Mar_Abr_May_Jun_Jul_Ago_Sept_Oct_Nov_Dic".split("_"),
		weekdays: "Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado".split("_"),
		weekdaysShort: "Dom_Lun_Mar_Mier_Jue_Vier_Sab".split("_"),
		weekdaysMin: "Do_Lu_Ma_Mi_Ju_Vi_Sa".split("_")
	}
);
moment.locale("es");
moment.tz.setDefault("America/Argentina/Buenos_Aires");

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			cacheTime: 0, // don't cache any query by default
			retry: 0,
		},
	},
});

function App() {
	return (
		<QueryClientProvider client={queryClient}>
		<div id="App">
		<UserContextProvider>
			<Helmet>
				<title>Conexión COMEX</title>
				<meta name="description" content="Conectamos profesionales de Comercio Exterior" />
				<meta name="keywords" content="comercio exterior, comex, despachante de aduana, agente de carga, importador, exportador, importar, exportar" />
			</Helmet>

			<AppHeader />

			<div id="AppBody">
				<ErrorBoundary>
					<ToastContainer position="top-center" />
					<Router primary={false}>
						<ScrollToTop path="/">
							<PageHomepage path="/" />
							<PageTerminosCondiciones path="terminos-y-condiciones" />
							<PagePoliticaPrivacidad path="politica-de-privacidad" />
							<PageContacto path="contacto" />
							<PagePlanes path="planes" />
							<PageUsersLogin path="login" />
							<PageComoFunciona path="como-funciona" />

							<Empty path="usuarios">
								<PageUsersRegistroConfirmado path="registro-confirmado" />
								<PageUsersRegistroNoConfirmado path="registro-no-confirmado" />
								<PageUsersCambioEmailConfirmado path="cambio-email-confirmado" />
								<PageUsersCambioEmailNoConfirmado path="cambio-email-no-confirmado" />
								<PageUsersRegistro path="registro" />
								<PageUsersOlvidoContrasena path="olvide-mi-contrasena" />
								<PageUsersRestablecerContrasena path="restablecer-contrasena/:hash" />
								<PageNotFound default />
							</Empty>

							<Empty path="busquedas">
								<PrivateRoute path="despachante-de-aduana">
									<PageBusquedasDespachante path="/" />
									<PageBuscarDespachante path="nueva" />
									<PageBusquedaDespachante path="ver/:id/*" />
									<PageEditarBusquedaDespachante path="editar/:id/*" />
									<PageNotFound default />
								</PrivateRoute>
								<PrivateRoute path="agente-de-carga">
									<PageBusquedasAgente path="/" />
									<PageBuscarAgente path="nueva" />
									<PageBusquedaAgente path="ver/:id/*" />
									<PageEditarBusquedaAgente path="editar/:id/*" />
									<PageNotFound default />
								</PrivateRoute>
								<PageNotFound default />
							</Empty>

							<PrivateRoute path="mi-perfil">
								<PageUsuariosDatosBasicos path="editar/datos-basicos" />
								<PageUsuariosSetupDespachanteDeAduana path="editar/datos-despachante" />
								<PageUsuariosSetupAgenteDeCarga path="editar/datos-agente" />
								<PageUsuariosSetupImex path="editar/datos-imex" />
								<PageUsuariosCambioContrasena path="cambiar-contrasena" />
								<PageUsuariosCambioEmail path="cambiar-email" />
								<PageNotFound default />
							</PrivateRoute>

							<PrivateRoute path="dashboard">
								<PageUsersDashboard path="/" />
								<PageNotFound default />
							</PrivateRoute>

							<AdminRoute path="admin">
								<PageAdminUsers path="usuarios" />
								<PageNotFound default />
							</AdminRoute>

							<PageNotFound default />
						</ScrollToTop>
					</Router>
				</ErrorBoundary>
			</div>

			<Container fluid="md">
				<footer className="p-5 my-5">
					<Nav className="justify-content-center">
						<Nav.Item><Nav.Link as={NavLink} to="/terminos-y-condiciones">Términos y condiciones</Nav.Link></Nav.Item>
						<Nav.Item><Nav.Link as={NavLink} to="/politica-de-privacidad">Política de privacidad</Nav.Link></Nav.Item>
						<Nav.Item><Nav.Link as={NavLink} to="/como-funciona">Cómo funciona</Nav.Link></Nav.Item>
						<Nav.Item><Nav.Link as={NavLink} to="/planes">Planes</Nav.Link></Nav.Item>
						<Nav.Item><Nav.Link as={NavLink} to="/contacto">Contacto</Nav.Link></Nav.Item>
						<Nav.Item><Nav.Link as="span">CONEXIONCOMEX.com <FaCopyright /> Copyright 2020</Nav.Link></Nav.Item>
					</Nav>
				</footer>
			</Container>
		</UserContextProvider>
		</div>
		</QueryClientProvider>
	);
}

export default App;
