import React from "react";
import Badge from "react-bootstrap/esm/Badge";

function Relevancia({ data }) {
	if (!data) {
		return null;
	}

	let variant;
	if (data.value >= 0.8) {
		variant = "success";
	} else if (data.value >= 0.6) {
		variant = "info";
	} else {
		variant = "secondary";
	}

	return <Badge variant={variant}>Relevancia: { data.string }</Badge>;
}

function RelevanciaItems({ data }) {
	if (!data) {
		return null;
	}

	return (
		<small>
			{ data.match_aduana ? <div>✓ Opera en esta <strong>Aduana</strong></div> : ""}
			{ data.match_rubro ? <div>✓ Se especializa en el <strong>Rubro</strong></div> : ""}
			{ data.match_tipo_operaciones ? <div>✓ Realiza tu <strong>tipo de operación</strong></div> : ""}
		</small>
	);
}

export { RelevanciaItems };
export default Relevancia;
