import React from "react";
import SlideToggle from "react-slide-toggle";
import { useUserContext } from "../../UserContext";
import { Loading } from "../ccQuery";
import FormCotizacionAgente from "./FormCotizacionAgente.js";
import CotizacionAgente from "./CotizacionAgente.js";

function BusquedaAgenteMiCotizacion({ busqueda, onSave, onCancelCotizacion }) {
	onSave = onSave ? onSave : () => {};
	onCancelCotizacion = onCancelCotizacion ? onCancelCotizacion : () => {};
	const userContext = useUserContext();
	const [ expandEvent, setExpandEvent ] = React.useState(false);
	const [ collapseEvent, setCollapseEvent ] = React.useState(false);

	const onEdit = () => {
		setExpandEvent(Date.now());
	};
	const onCancel = () => {
		setCollapseEvent(Date.now());
	};
	const onSaveLocal = () => {
		setCollapseEvent(Date.now());
		onSave();
	};
	const onDeleteCotizacion = () => {
		setExpandEvent(Date.now());
		onCancelCotizacion();
	};

	if (!userContext.user) {
		return <Loading />;
	}

	const cotizacion = busqueda.cotizaciones && busqueda.cotizaciones.length ? busqueda.cotizaciones[0] : null;

	return (
		<>
			{
				cotizacion ?
					<CotizacionAgente
						busqueda={busqueda}
						cotizacion={cotizacion}
						onCancelCotizacion={onDeleteCotizacion}
						onEdit={onEdit}
					/> :
					null
			}

			<SlideToggle collapsed={!!cotizacion} expandEvent={expandEvent} collapseEvent={collapseEvent}>
				{({ setCollapsibleElement }) => (
					<div ref={setCollapsibleElement}>
						<FormCotizacionAgente busqueda={busqueda} cotizacion={cotizacion} onSave={onSaveLocal} onCancel={onCancel} />
					</div>
				)}
			</SlideToggle>
		</>
	);
}

export default BusquedaAgenteMiCotizacion;
