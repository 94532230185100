import React from "react";
import Container from 'react-bootstrap/Container';
import LoginForm from "../../Components/LoginForm";

function PageUsersRegistroConfirmado() {
	return (
		<div className="PaddedPage">
			<Container className="NarrowPage">
				<h1>Listo, tu cuenta ya se encuentra habilitada!</h1>
				<p>Por seguridad, ingresá tu email y contraseña nuevamente para acceder a tu cuenta:</p>
				<LoginForm />
			</Container>
		</div>
	);
}

export default PageUsersRegistroConfirmado;
